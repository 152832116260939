import React, { useState, useEffect } from 'react';
import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { connect } from 'react-redux';
import './style.css';
import TeamInfo from './TeamInfo';

import { useNavigate } from 'react-router-dom';

const AboutUs = (props) => {

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);
  const signMeIn = () => {
    navigate('/Login');
  };

  useEffect(() => {
    if (!menuHidden) {
      document.body.setAttribute("style", "overflow: hidden; position: static;");
    } else {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    }
    // Cleanup function to reset the body style when the component unmounts or when menuHidden changes again
    return () => {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    };
  }, [menuHidden]);

  useEffect(() => {
    document.body.setAttribute("style", "overflow: visible; position: static;");
  }, []);

  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };


  const isMenuOpen = (state) => {
    setMenuHidden(!state.isOpen);
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  return (
    <div id="outer-container">
      <HamburgerMenu isMenuOpenFunc={isMenuOpen} pageWrapId="page-wrap" outerContainerId="outer-container" isOpenStatus={menuHidden} history={props.history} callbackClose={callbackRoutingCloseMenu} />
      <div id="page-wrap">
        <Header openMenu={openMenuWindow} openCart={openFloatCart} signIn={signMeIn} history={props.history} />

        <FloatCart isTheCartOpen={isOpen} history={props.history} />
        <h1 style={{ textAlign: "center", fontFamily: "Old Standard TT" }}>About us</h1>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <a style={{ display: "inline-block", borderTopStyle: "solid" }}>
            &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
          </a>
        </div>
        <div style={{ fontFamily: "serif", textAlign: "center", marginLeft: '10px', marginRight: '10px' }}>
          <div>
            <Row>
              <Col>
                <section className="intro-section">
                  <div>
                    <div className="intro-header">
                      <h1>Ghumman Tech: Beyond Technology, Building Solutions</h1>
                      <p>Your innovation partner, bridging the gap between technology and your aspirations.</p>
                    </div>

                    <div className="intro-services">
                      <h2>Our Services</h2>
                      <ul>
                        <h3>Mobile App & Game Development</h3>
                        <p>Crafting stunning, user-centric experiences that drive results.</p>
                        <h3>Web Development</h3>
                        <p>Building visually stunning and functional platforms that showcase your brand.</p>
                        <h3>Internet of Things (IoT)</h3>
                        <p>Helping you leverage the power of connected devices to optimize operations and create seamless experiences.</p>
                        <h3>Cloud Infrastructure Setup</h3>
                        <p>Designing and implementing secure, scalable cloud solutions to meet your business needs.</p>
                        <h3>Cybersecurity</h3>
                        <p>Protecting your infrastructure and data from evolving threats with comprehensive strategies.</p>
                        <h3>Seminars & Workshops</h3>
                        <p>Fostering a tech-savvy community through knowledge-sharing events.</p>
                        <h3>Online Store</h3>
                        <p>Offering a curated selection of IoT devices and books to empower your tech journey.</p>
                      </ul>
                    </div>
                  </div>
                </section>
              </Col>
            </Row>
            <TeamInfo />
          </div>
        </div>

        <Footer history={props.history} />


      </div>
    </div>
  );

}

const mapStateToProps = (state) => (
  {
    authenticate: state.authenticate,
    cartProducts: state.cart.products,
    newProduct: state.cart.productToAdd,
    productToRemove: state.cart.productToRemove,
    productToChange: state.cart.productToChange,
    cartTotal: state.total.data
  })

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) =>
    dispatch({ type: 'UPDATE', username: username, password: password }),
  signout: () => dispatch({ type: 'LOGOUT' }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutUs);
