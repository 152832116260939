import 'babel-polyfill';
import React, { useEffect } from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Route, BrowserRouter as Router } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, useRoutes, useNavigate } from 'react-router-dom';


import Root from './Root';
import MainPage from './components/MainPage';
import SoftwareServices from './components/SoftwareServices';
import ReadBook from './components/ReadBook';
import Collections from './components/Collections';
import CollectionCategory from './components/CollectionCategory';
import Product from './components/Product';
import MyAccount from './components/MyAccount';
import Checkout from './components/Checkout';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import SizeChart from './components/SizeChart';
import Exchange from './components/Exchange';
import Privacy from './components/Privacy';
import UserAgreement from './components/UserAgreement';
import Thankyou from './components/ThankyouSoftware';
import Survey from './components/Extras/Survey';
import AIORecorder from './components/Extras/AppsPrivacyPolicies/AIORecorder';
import GhummanGharianPolicy from './components/Extras/AppsPrivacyPolicies/GhummanGharian';
import GymMotivationPolicy from './components/Extras/AppsPrivacyPolicies/GymMotivation';
import PplPolicy from './components/Extras/AppsPrivacyPolicies/Ppl';
import ScubaPolicy from './components/Extras/AppsPrivacyPolicies/Scuba';
import LinuxPolicy from './components/Extras/AppsPrivacyPolicies/Linux';
import FlashcardsPolicy from './components/Extras/AppsPrivacyPolicies/Flashcards';
import CountryFlagsCapitalsPolicy from './components/Extras/AppsPrivacyPolicies/CountryFlagsCapitals';
import USFlagsCapitalsPolicy from './components/Extras/AppsPrivacyPolicies/USFlagsCapitals';
import FruitsAndVegetablesPolicy from './components/Extras/AppsPrivacyPolicies/FruitsAndVegetables';
import PopularSportsPolicy from './components/Extras/AppsPrivacyPolicies/PopularSports';
import LearnLanguagePolicy from './components/Extras/AppsPrivacyPolicies/LearnLanguage';
import IqbalDemystifiedPolicy from './components/Extras/AppsPrivacyPolicies/IqbalDemystifiedPolicy';
import IqbalDemystifiedDeleteAccount from './components/Extras/AppsPrivacyPolicies/IqbalDemystifiedDeleteAccount';
import TasweerEBautan from './components/Extras/TasweerEBautan';

import Change from './components/Authentication/Change';
import EmailConfirmation from './components/Authentication/EmailConfirmation';
import Forgot from './components/Authentication/Forgot';
import Login from './components/Authentication/Login';
import Register from './components/Authentication/Register';
import ResetPassword from './components/Authentication/ResetPassword';
import Payment from './components/Authentication/Payment';

import './index.css';

import Seminars from './components/Seminars';
import Seminar from './components/Seminar';

import Jobs from './components/Jobs';
import Job from './components/Job';

import Course from './components/Course';
import ThankyouSeminar from './components/ThankyouSeminar';
import ThankyouTrialClass from './components/ThankyouTrialClass';
import ThankyouMember from './components/ThankyouMember';

import BuyMembership from './components/BuyMembership';
import GhummanRoboTrial from './components/GhummanRoboTrial';

import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from 'react-ga';

// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { ToastContainer } from 'react-toastify';


// optional cofiguration
const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
};

const initializeHotjar = () => {
  if (window.hj) {
    window.hj('trigger', 'website-analytics');
  }
};

const TrackPageView = ({ children }) => {
  useEffect(() => {
    initializeHotjar(); // Initialize Hotjar on route change
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return children;
};

const CoursesRedirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/');
  }, [navigate]);

  return null;
};

const ProfileCoursesRedirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/MyAccount/Courses');
  }, [navigate]);

  return null;
};

const App = () => {
  const routes = useRoutes([
    { path: '/', element: <MainPage /> },
    { path: '/SoftwareServices', element: <SoftwareServices /> },
    { path: '/ReadBook', element: <ReadBook /> },
    { path: '/Categories', element: <Collections /> },
    { path: '/Categories/:id', element: <CollectionCategory /> },
    { path: '/Categories/Product', element: <CoursesRedirect /> },
    { path: '/Categories/Product/:id', element: <Product /> },
    { path: '/MyAccount', element: <MyAccount /> },
    { path: '/MyAccount/:tabName', element: <MyAccount /> },
    { path: '/Checkout', element: <Checkout /> },
    { path: '/AboutUs', element: <AboutUs /> },
    { path: '/ContactUs', element: <ContactUs /> },
    { path: '/SizeChart', element: <SizeChart /> },
    { path: '/Exchange', element: <Exchange /> },
    { path: '/Privacy', element: <Privacy /> },
    { path: '/UserAgreement', element: <UserAgreement /> },
    { path: '/Thankyou', element: <Thankyou /> },
    { path: '/ThankyouSeminar', element: <ThankyouSeminar /> },
    { path: '/ThankyouTrialClass', element: <ThankyouTrialClass /> },
    { path: '/ThankyouMember', element: <ThankyouMember /> },
    { path: '/Change', element: <Change /> },
    { path: '/EmailConfirmation', element: <EmailConfirmation /> },
    { path: '/Forgot', element: <Forgot /> },
    { path: '/Login', element: <Login /> },
    { path: '/Register', element: <Register /> },
    { path: '/ResetPassword', element: <ResetPassword /> },
    { path: '/Payment', element: <Payment /> },
    { path: '/just-for-aisha-baji', element: <Survey /> },
    { path: '/screen-recorder-policy', element: <AIORecorder /> },
    { path: '/ghumman-gharian-policy', element: <GhummanGharianPolicy /> },
    { path: '/gym-motivation-policy', element: <GymMotivationPolicy /> },
    { path: '/ppl-policy', element: <PplPolicy /> },
    { path: '/scuba-policy', element: <ScubaPolicy /> },
    { path: '/linux-policy', element: <LinuxPolicy /> },
    { path: '/flashcards-policy', element: <FlashcardsPolicy /> },
    { path: '/country-flags-capitals-policy', element: <CountryFlagsCapitalsPolicy /> },
    { path: '/us-flags-capitals-policy', element: <USFlagsCapitalsPolicy /> },
    { path: '/fruits-and-vegetables-policy', element: <FruitsAndVegetablesPolicy /> },
    { path: '/popular-sports-policy', element: <PopularSportsPolicy /> },
    { path: '/learn-language-policy', element: <LearnLanguagePolicy /> },
    { path: '/iqbal-privacy-policy', element: <IqbalDemystifiedPolicy /> },
    { path: '/iqbal-deletion-policy', element: <IqbalDemystifiedDeleteAccount /> },
    { path: '/tasweer-e-bautan', element: <TasweerEBautan /> },
    { path: '/Seminars', element: <Seminars /> },
    { path: '/Seminars/:id', element: <Seminar /> },
    { path: '/Camps', element: <Seminars /> },
    { path: '/Camps/:id', element: <Seminar /> },
    { path: '/Webinars/:id', element: <Seminar /> },
    { path: '/Careers', element: <Jobs /> },
    { path: '/Careers/:id', element: <Job /> },
    { path: '/Courses/:id', element: <Course /> },
    { path: '/Courses', element: <ProfileCoursesRedirect /> },
    { path: '/BuyMembership', element: <BuyMembership /> },
    { path: '/GhummanRoboTrial', element: <GhummanRoboTrial /> },
  ]);

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [routes]);

  return routes;
};


const routing = (

  <GoogleOAuthProvider clientId="112928142549-u72h2t70je9dueudensc6h98rh7an5d2.apps.googleusercontent.com">
    <Root>
      <AlertProvider template={AlertTemplate} {...options}>
        <div className='indexClass' style={{
          backgroundColor: 'white'
        }}>
          <Router>
            <ToastContainer />
            <App />

            {/* <TawkMessengerReact
              propertyId="663396d5a0c6737bd133b152"
              widgetId="1hssoac5o" /> */}
          </Router>
        </div>
      </AlertProvider>
    </Root>
  </GoogleOAuthProvider>

);


const root = document.getElementById('root');
createRoot(root).render(<TrackPageView>{routing}</TrackPageView>);