import React, { useState, useEffect } from 'react';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import { getBaseUrl } from '../Utils/getBaseUrl';
import Alert from 'react-bootstrap/Alert';
import Fade from 'react-bootstrap/Fade';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import useRegisterMember from './useRegisterMember';

const CheckoutForm = (props) => {

    const registerMember = useRegisterMember();


    const { stripe, elements, seminar, planType } = props;
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        phone: '',
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCoupon, setIsLoadingCoupon] = useState(false);
    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showCoupon, setShowCoupon] = useState(false);
    const [currentCoupon, setCurrentCoupon] = useState('');
    const [finalCoupon, setFinalCoupon] = useState(null);
    const [couponMessage, setCouponMessage] = useState('');
    const [currentAmount, setCurrentAmount] = useState(seminar.price);
    const [couponId, setCouponId] = useState(null);
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    const baseUrl = getBaseUrl();

    useEffect(() => {
        if (showError) {
            const timeoutId = setTimeout(() => {
                setShowError(false);
            }, 2000); // 2 seconds in milliseconds

            return () => clearTimeout(timeoutId);
        }
    }, [showError]);

    useEffect(() => {
        if (show) {
            const timeoutId = setTimeout(() => {
                setShow(false);
            }, 2000); // 2 seconds in milliseconds

            return () => clearTimeout(timeoutId);
        }
    }, [show]);

    useEffect(() => {
        if (showMessage) {
            const timeoutId = setTimeout(() => {
                setShowMessage(false);
            }, 2000); // 2 seconds in milliseconds

            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);


    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
        setErrors({}); // Clear any previous errors on change
    };

    const handleSubmitCoupon = async (event) => {

        try {
            setIsLoadingCoupon(true);


            event.preventDefault();
            const dataRequestCoupon = {
                couponCode: currentCoupon,
                seminarId: seminar.id
            };

            const response = await fetch(`${baseUrl}/api/check-coupon`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(dataRequestCoupon),
            });
            const data = await response.json();

            if (response.status === 200) {
                const newAmount = currentAmount - data.couponDetails.discount;
                if (newAmount < 0) {
                    newAmount = 0;
                }
                setCurrentAmount(newAmount);
                setFinalCoupon(currentCoupon);
                setCouponId(data.couponDetails.id);
                setShowCoupon(true);
                setCouponMessage('Coupon Applied')
            } else {
                setCouponMessage("Coupon doesn't exist")
            }
        } catch (error) {
        } finally {
            setIsLoadingCoupon(false);
        }
    };

    const registerMemberAfterSuccessfulSubscription = async ( paymentMethodId) => {
        const dataRequestRegister = {
            email: formData.email,
            name: formData.name,
            phone_number: formData.phone,
            payment_method_id: paymentMethodId,
            has_had_trial: 1,
            subscription_status: 'active',
        };
        const responseRegister = await registerMember(dataRequestRegister, formData.name);
        // responseRegister should not be returned. 
        // as at this point payment has been collected
        // and registerMember is persiting the entry in DB
        // registerMember is supposed to send user to ThankyouSeminar page
        if (responseRegister.status === 500) {
            setShowError(true);
        }
    }

    const handlePayment = async (event) => {
        try {
            setIsLoading(true);

            event.preventDefault();
            // Basic sanity checks
            const validationErrors = {};
            if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
                validationErrors.email = 'Please enter a valid email address.';
            }
            if (!formData.name) {
                validationErrors.name = 'Please enter your name.';
            }
            if (!formData.phone) {
                validationErrors.phone = 'Please enter your phone number.';
            }

            setErrors(validationErrors);

            if (Object.keys(validationErrors).length === 0) {

                const dataRequestCheckSubscriptionStatus = {
                    email: formData.email,
                };
    
                const responseDataRequestCheckSubscriptionStatus = await fetch(`${baseUrl}/api/check-subscription-status`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(dataRequestCheckSubscriptionStatus),
                });
                const dataSubscriptionStatus = await responseDataRequestCheckSubscriptionStatus.json();
                if (dataSubscriptionStatus.message === 'active') {
                    setMessage('You are already a member');
                    setShowMessage(true);
                    return;
                }
                
                if (currentAmount <= 0) {
                    await registerMemberAfterSuccessfulSubscription();
                    return;
                }
            
                if (!stripe || !elements) {
                    console.log('not loaded')
                    // Stripe.js has not loaded yet. Make sure to disable your form or show a loading indicator.
                    return;
                }
            
                // Create a payment method
                const { paymentMethod, error } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: formData.name,
                        email: formData.email,
                        phone: formData.phone
                    },
                });
            
                if (error) {
                    console.log('error', error);
                    setShowError(true);
                    return;
                }
            
                const dataRequest = {
                    email: formData.email,
                    name: formData.name,
                    phone: formData.phone,
                    planType,
                    trialPeriodDays: 1,
                    paymentMethodId: paymentMethod.id, // Include the payment method ID
                };
            
                const response = await fetch(`${baseUrl}/api/stripe-secret-subscription`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(dataRequest),
                });
                const data = await response.json();
            
                /* if data.clientSecret exists then you can charge the money right now
                this would mean that user was not granted a trial period. 
                Otherwise stripe will charge the user after the trial period ends. 
                And trigger a webhook to your server to notify you of the payment.
                */ 
                if (data.clientSecret) {
                    // Use cardElement to confirm the payment
                    const result = await stripe.confirmCardPayment(data.clientSecret, {
                        payment_method: paymentMethod.id,
                    });
                    if (result.error) {
                        setShowError(true);
                    } else {
                        await registerMemberAfterSuccessfulSubscription( paymentMethod.id);
                    }
                } else {
                    await registerMemberAfterSuccessfulSubscription( paymentMethod.id);
                }
                
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Container>
                <Row>
                    <Col lg={6}>
                        <form onSubmit={handlePayment}>
                            <fieldset>
                                <legend>Contact Information</legend>
                                <div className='userInfoSection'>
                                    <div>
                                        <label htmlFor="email">Email:</label>
                                        <input
                                            className="InputUserInfo"
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                        {errors.email && <p className="error">{errors.email}</p>}
                                    </div>
                                    <div>
                                        <label htmlFor="name">Name:</label>
                                        <input
                                            className="InputUserInfo"
                                            style={{ backgroundColor: 'white' }}
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                        {errors.name && <p className="error">{errors.name}</p>}
                                    </div>
                                    <div>
                                        <label htmlFor="phone">Phone:</label>
                                        <input
                                            className="InputUserInfo"
                                            type="tel"
                                            id="phone"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            required
                                        />
                                        {errors.phone && <p className="error">{errors.phone}</p>}
                                    </div>
                                </div>
                                {currentAmount > 0 &&
                                    <div className='cardInfoSection'>
                                        <CardElement />
                                    </div>
                                }
                                <div className='buttonSection'>
                                    <button 
                                        type="submit" 
                                        disabled={isLoading || show}
                                        style={{
                                            backgroundColor: isLoading || show ? '#808080' : '#0000ff',
                                            color: '#ffffff'
                                        }}
                                    >
                                        {currentAmount > 0 ? 'Pay' : 'Become Member'}
                                    </button>
                                </div>
                            </fieldset>

                        </form>
                    </Col>
                    <Col lg={6} style={{padding: '20px'}}>
                        <form>
                            <Row>
                                <Card className="text-center">
                                    <Card.Body>
                                        <Card.Title>First 7 Days on us</Card.Title>
                                        <Card.Title>Your card will not be charged for first 7 days.</Card.Title>
                                        <Card.Title>You can cancel your subscription free of charge anytime during the trial.</Card.Title>
                                        <Card.Title>After Trial Period</Card.Title>
                                        <Card.Title>Total Amount</Card.Title>
                                        <Card.Text>
                                            {planType === 'Yearly' ? (
                                                <p><del>9.99 USD / month</del> <br /> {seminar.price} USD / year (8.33 USD / month)</p>
                                            ) : (
                                                <p>{seminar.price} USD / month</p>
                                            )}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Row>
                            {/* <Row>
                                <label>Coupon:</label>
                                <input
                                    type="text"
                                    style={{ margin: '10px', backgroundColor: 'white' }}
                                    id="couponInput"
                                    name="coupon"
                                    value={currentCoupon}
                                    onChange={(event) => setCurrentCoupon(event.target.value)}
                                    disabled={isLoadingCoupon || showCoupon}
                                />
                            </Row>
                            <Row>
                                <Col></Col>
                                <Col>
                                <button
                                    type="submit"
                                    disabled={isLoadingCoupon || showCoupon}
                                    onClick={handleSubmitCoupon}
                                    style={{
                                        backgroundColor: showCoupon ? '#808080' : '#0000ff',
                                        color: '#ffffff',
                                    }}
                                >
                                    Apply
                                </button>
                                {couponMessage && <p className="success">{couponMessage}</p>}
                                </Col>
                                <Col></Col>
                            </Row> */}
                        </form>
                    </Col>
                </Row>
            </Container>
            <Fade in={showError}>
                <Alert show={showError} variant="danger" className="top-alert" onClose={() => setShowError(false)} dismissible>
                    Payment Declined. Registration was not successful.
                </Alert>
            </Fade>
            <Fade in={show}>
                <Alert show={show} variant="success" className="top-alert" onClose={() => setShow(false)} dismissible>
                    Registration completed Successfully. We're thrilled you're joining! Look for an email with the Zoom link shortly.
                </Alert>
            </Fade>

            <Fade in={showMessage}>
                <Alert show={showMessage} variant="success" className="top-alert" onClose={() => setShowMessage(false)} dismissible>
                    {message}
                </Alert>
            </Fade>
        </div>
    );
};

// export default CheckoutForm;
export default function InjectedCheckoutForm(props) {

    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CheckoutForm stripe={stripe} elements={elements} seminar={props.seminar} planType = {props.planType} />
            )}
        </ElementsConsumer>
    );
}