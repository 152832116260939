import React, {useState, useEffect} from 'react';


import ShelfCollection from '../ShelfCollection';
import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';

import { updateFilters } from '../../services/filters/actions';
import { updateFiltersCategory } from '../../services/filtersCategory/actions';

import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';


import './style.css';

const CollectionCategory = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);
  const [pageCategoryNameState, setPageCategoryNameState] = useState(id);

  const signMeIn = () => {
    navigate('/Login');
  };

  useEffect(() => {
    if (id !== pageCategoryNameState) {
      props.updateFilters({ 'category': [id] });
      setPageCategoryNameState(id);
    }
  }, [id, pageCategoryNameState, props.updateFilters])

  useEffect(() => {
    if (!menuHidden) {
      document.body.setAttribute("style", "overflow: hidden; position: static;");
    } else {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    }
    // Cleanup function to reset the body style when the component unmounts or when menuHidden changes again
    return () => {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    };
  }, [menuHidden]);


  useEffect(() => {

    document.body.setAttribute("style", "overflow: visible; position: static;");
    props.updateFilters({ 'category': [pageCategoryNameState] });

  }, []);

  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };


  const isMenuOpen = (state) => {
    setMenuHidden(!state.isOpen);
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

    var tempResult = pageCategoryNameState.replace(/([A-Z])/g, " $1");
    var pageTitle = tempResult.charAt(0).toUpperCase() + tempResult.slice(1);

    return (
      <div id="outer-container">
        <HamburgerMenu isMenuOpenFunc={isMenuOpen} pageWrapId="page-wrap" outerContainerId="outer-container" isOpenStatus={menuHidden} history={props.history} callbackClose={callbackRoutingCloseMenu} />
        <div id="page-wrap">
          <Header openMenu={openMenuWindow} openCart={openFloatCart} signIn={signMeIn} history={props.history} />

          <h1 style={{ textAlign: "center", fontFamily: "Old Standard TT" }}>{pageTitle}</h1>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <a style={{ display: "inline-block", borderTopStyle: "solid" }}>
              &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
            </a>
          </div>

          <React.Fragment>
            <main>
              <ShelfCollection history={props.history} categoryName={pageCategoryNameState} />
            </main>
            <FloatCart isTheCartOpen={isOpen} history={props.history} />
          </React.Fragment>


          <Footer history={props.history} />


        </div>
      </div>
    );

}

const mapStateToProps = (state) => (
  {
    authenticate: state.authenticate,
    cartProducts: state.cart.products,
    newProduct: state.cart.productToAdd,
    productToRemove: state.cart.productToRemove,
    productToChange: state.cart.productToChange,
    cartTotal: state.total.data,
    filters: state.filters.items,
    filtersCategory: state.filtersCategory.items,

  })

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) =>
    dispatch({ type: 'UPDATE', username: username, password: password }),
  signout: () => dispatch({ type: 'LOGOUT' }),
});

export default connect(
  mapStateToProps,
  { mapDispatchToProps, updateFilters, updateFiltersCategory }
)(CollectionCategory);
