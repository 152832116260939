import React, { useState } from 'react';
import './style.css';
import BurgerMenu from 'react-burger-menu';
import { slide as SlideMenu } from 'react-burger-menu';
// import { withRouter } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import BuyMembershipButton from './BuyMembershipButton';
import ProfileButton from './ProfileButton';
import GhummanRoboTrialButton from './GhummanRoboTrialButton';

const HamburgerMenu = (props) => {

  const navigate = useNavigate();
  const [currentMenu, setCurrentMenu] = useState('slide');

  const contactOpen = () => {
    props.callbackClose();
    navigate('/ContactUs');
  }

  const goToCareers = () => {
    props.callbackClose();
    navigate('/Careers');
  }

  const aboutOpen = () => {
    props.callbackClose();
    navigate('/AboutUs');
    // window.open('https://www.facebook.com/pg/SabaJC/about/?ref=page_internal');
  }

  const goToSeminars = () => {
    props.callbackClose();
    navigate('/Seminars');
  }

  const goToSoftwareServices = () => {
    props.callbackClose();
    navigate('/SoftwareServices');
  }

  const goToSummerCamp = () => {
    props.callbackClose();
    navigate('/Camps/10');
  }


  const goToPAGE = (pageName, categoryName) => {
    props.callbackClose();
    navigate({
      pathname: `/Categories/${categoryName}`,
      state: { pageName: pageName, categoryName: categoryName }
    });

  }

  const getMenu = () => {
    if (!props.isOpenStatus) {
      return (
        <SlideMenu
          className="bm-menu"
          id={currentMenu}
          pageWrapId={props.pageWrapId}
          outerContainerId={props.outerContainerId}
          right={props.side === 'right'}
          isOpen={!props.isOpenStatus}
          disableOverlayClick={false}
          onStateChange={props.isMenuOpenFunc}
          customBurgerIcon={false}
          styles={{
            bmMenu: {
              background: 'white',
            },
            bmItemList: {
              background: 'white',
              color: 'black',
            },
            bmItem: {
              background: 'white',
              color: 'black', // Font color for menu items
            },
            bmItemSmall: {
              color: 'black', // Font color for small menu items
            },
            bmCrossButton: {
              color: 'black', // Color for the cross button
              background: 'red',
              opacity: 1,
            },
            bmCross: {
              background: 'white', // Color of the cross
            },
          }}
        >
          <div className="bm-item-list" style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto'  }}>
            <div className="bm-item" onClick={() => goToSeminars('Seminars', 'seminars')} style={{ borderBottom: "solid" }}>Seminars</div>
            <div className="bm-item" onClick={goToSoftwareServices} style={{ borderBottom: "solid" }}>Services</div>
            {/* <div className="bm-item" onClick={() => goToPAGE('Handicrafts', 'handicrafts')} style={{ borderBottom: "solid" }}>Handicrafts</div> */}
            <div className="bm-item" onClick={() => goToPAGE('Books', 'books')} style={{ borderBottom: "solid" }}>Books</div>
            <div style={{ borderBottom: "solid" }}>
              <div className="bm-item" onClick={() => goToPAGE('Courses', 'courses')} > Courses</div>
              <div className="bm-sub-item" onClick={() => goToPAGE('Language Courses', 'languages')} > Languages</div>
              <div className="bm-sub-item" onClick={() => goToPAGE('Programming Courses', 'programming')} > Programming</div>
              <div className="bm-sub-item" onClick={() => goToPAGE('Certification Courses', 'certifications')} > Certifications</div>
              <div className="bm-sub-item" onClick={() => goToPAGE('Kid Courses', 'kids')} > Courses for Kids</div>
              <div className="bm-sub-item"  style={{color: 'red'}} onClick={() => goToPAGE('Instructor-Led', 'live')} > Instructor-Led</div>
            </div>
            <div style={{ borderBottom: "solid" }}>
              <div className="bm-item" onClick={goToSummerCamp} > Kids Camp 2024</div>
            </div>
            <div className="bm-item-small" onClick={aboutOpen}>About Us</div>
            <div className="bm-item-small" onClick={contactOpen}>Contact Us</div>
            <div className="bm-item-small" onClick={goToCareers}>Careers</div>
            <div><BuyMembershipButton /></div>
            <div style={{marginTop: '10px'}}><GhummanRoboTrialButton /></div>
            <div><ProfileButton /></div>
          </div>
        </SlideMenu>
      );
    }
  };


  return (
    <div>
      {getMenu()}
    </div>
  );
}


// export default withRouter(HamburgerMenu);
export default HamburgerMenu;
