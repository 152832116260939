import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';

const BuyMembershipButton = () => {
  const navigate = useNavigate();

  return (
    <div>
      <button 
        className='headerButtonStyle' 
        onClick={() => navigate('/BuyMembership')}
      >
        Buy membership
      </button>
    </div>
  );
};

export default BuyMembershipButton;