import React from 'react';

const GameYoutube = () => {
    return (
        <div>
        <div style={{ textAlign: 'center', padding: '20px', textDecoration: 'underline' }}>
          <p className='platform-title'>Mobile Games</p>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <iframe
            src="https://youtube.com/embed/tq9MO_Zdp2M?autoplay=1&mute=1&loop=1&controls=0&modestbranding=1&rel=0&showinfo=0&playlist=tq9MO_Zdp2M"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Target Hunting"
            width="640" // Adjusted width for larger display
            height="360" // Adjusted height for larger display
             />
        </div>
        <div>
          <p className="caption">Android and iOS FPS Game</p>
        </div>
        </div>
    )
}

export default GameYoutube; 