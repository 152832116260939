import React, { useState, useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';


import seminars from '../../assets/Seminars.jpg';
import services from '../../assets/Services.jpg';
import products from '../../assets/Products.jpg';

function CompanyDivisionCards() {


  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} sm={6} md={4}>
          <Link to="/Seminars">

            <Card border="success" style={{ width: '18rem', height: '40rem', margin: '0 auto' }}>
              <Card.Img variant="top" src={seminars} />
              <Card.Body style={{ textAlign: 'center' }}>
                <Col>
                  <Row style={{ display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold' }}>
                    Seminars
                  </Row>
                </Col>
              </Card.Body>
            </Card>
          </Link>

        </Col>


        <Col xs={12} sm={6} md={4}>
          <Link to="/SoftwareServices">

            <Card border="success" style={{ width: '18rem', height: '40rem', margin: '0 auto' }}>
              <Card.Img variant="top" src={services} />
              <Card.Body style={{ textAlign: 'center' }}>
                <Col>
                  <Row style={{ display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold' }}>
                    Services
                  </Row>
                </Col>
              </Card.Body>
            </Card>

          </Link>
        </Col>


        <Col xs={12} sm={6} md={4}>
          <Link to="/Categories">
            <Card border="success" style={{ width: '18rem', height: '40rem', margin: '0 auto' }}>
              <Card.Img variant="top" src={products} />
              <Card.Body style={{ textAlign: 'center' }}>
                <Col>
                  <Row style={{ display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold' }}>
                    Products
                  </Row>
                </Col>
              </Card.Body>
            </Card>
          </Link>

        </Col>
      </Row>
    </Container>
  );
}

export default CompanyDivisionCards;