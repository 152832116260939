import React, { useState, useEffect, useRef } from 'react';

import { toast } from 'react-toastify';

import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';

import Button from 'react-bootstrap/Button'

import { updateFilters } from '../../services/filters/actions';
import { fetchProduct } from '../../services/shelf/actions';
import { addProduct } from '../../services/cart/actions';

import { connect } from 'react-redux';

import './style.css';

import {
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";

import {
  FacebookIcon as ShareFacebookIcon,
  TwitterIcon,
} from "react-share";

import Carousel from 'react-bootstrap/Carousel';
import Chip from '@mui/material/Chip';

import { useNavigate, useParams, useLocation } from 'react-router-dom';

const Product = (props) => {

  const navigate = useNavigate();
  const { id } = useParams();
  const currentUrl = useLocation().pathname;


  const [email, setEmail] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSizeIndex, setSelectedSizeIndex] = useState(0);
  const [selectedSizeName, setSelectedSizeName] = useState('');
  const [selectedKey, setSelectedKey] = useState(null);

  const handleClick = (key) => {
    setSelectedKey(key);
  };

  const prevFilters = useRef(props.filters);

  const handleFetchProduct = (
    sku = sku
  ) => {
    setIsLoading(true);
    props.fetchProduct(sku, () => {
      setIsLoading(false);
    });
  };


  useEffect(() => {
    // Compare the current filters with the previous filters
    if (prevFilters.current !== props.filters) {
      // If they are different, perform the necessary action
      handleFetchProduct(id);
      // Update the previous filters to the current filters
      prevFilters.current = props.filters;
    }
  }, [props.filters, handleFetchProduct, id]);



  const signMeIn = () => {
    navigate('/Login');
  };



  useEffect(() => {
    document.body.setAttribute("style", "overflow: visible; position: static;");
    const resultEmail = localStorage.getItem("email");
    if (resultEmail !== null && resultEmail !== '') {
      setEmail(resultEmail);
    }
    props.fetchProduct({ 'sku': id });
    fillAvailableSizes();
  }, [id]);

  useEffect(() => {
    if (!menuHidden) {
      document.body.setAttribute("style", "overflow: hidden; position: static;");
    } else {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    }
    // Cleanup function to reset the body style when the component unmounts or when menuHidden changes again
    return () => {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    };
  }, [menuHidden]);

  const fillAvailableSizes = () => {
    const availableSizes = props.products[0] ? props.products[0].availableSizes : [];
    setAvailableSizes(availableSizes);
  };

  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };

  const isMenuOpen = (state) => {
    if (state.isOpen === false) {
      setMenuHidden(true);
    } else {
      setMenuHidden(false);
    }
    return state.isOpen;
  };

  const canItemBeAdded = (item) => {
    for (let i = 0; i < props.cartProducts.length; i++) {
      if (props.cartProducts[i].id === item.id) {
        return false; // Item is already in the cart
      }
    }
    return true;
  };

  const notify = () => {
    // toast('Only one book/course can be purchased at a time. If you already have a book/course in your cart, you need to remove it to add this product');
    toast('You already have added this product to the cart.');
  };


  const addThisProductToCart = (item) => {
    if (canItemBeAdded(item)) {
      const selectedSize = selectedSizeName || (props.products[0] && props.products[0].availableSizes && props.products[0].availableSizes[0]);
      props.addProduct({ ...item, selectedSize, quantity: 1 });
    } else {
      notify();
    }
  };

  const sizeSelectedPressed = (productSize, index) => {
    setSelectedSizeName(productSize);
    setSelectedSizeIndex(index);
  }

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  }

  let itemSizes;
  if (availableSizes != null) {
    itemSizes = availableSizes.map((item) =>
      <span key={item.index}> {item}&nbsp;&nbsp;</span>
    )
  }
  else {
    itemSizes = null;
  }

  const { products } = props;

  const productFormatWithCurrency = (item) => {
    return (
      <>
        <div style={{
          textAlign: "center", fontWeight: "400", margin: "0 0 0.5em",
          lineHeight: "1.4", fontFamily: "auto",
        }}>
          <div>
            {
              item.price !== item.actualPrice && (
                <div>

                  🏷️ $<span style={{ textDecoration: 'line-through' }}><span> </span>{item.actualPrice} USD</span>
                </div>)}
          </div>
          $<span> </span>
          {item.price}<span> </span>
          USD for 3 months
        </div>
        <div style={{
          textAlign: "center", fontWeight: "400", margin: "0 0 0.5em",
          lineHeight: "1.4", fontFamily: "auto",
        }}>
          Included in Membership
        </div>
      </>
    )
  }

  let productDetails;

  if (products != null) {
    productDetails = products.map((item) => {


      return (
        <div>
          <div style={{
            textAlign: "center", fontWeight: "400", margin: "0 0 0.5em",
            lineHeight: "1.4", fontFamily: "auto", fontSize: "40px"
          }}>
            {item.title}
          </div>


          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {item?.status === 'active' &&
              (<Chip label="Active" style={{ fontWeight: 'bold', backgroundColor: 'green', color: 'white' }} />)
            }
            {item?.status === 'completed' &&
              (<Chip label="Expired" style={{ fontWeight: 'bold', backgroundColor: 'red', color: 'white' }} />)
            }
            {item?.status === 'soon' &&
              (<Chip label="Comming Soon" style={{ fontWeight: 'bold', backgroundColor: 'yellow', color: 'black' }} />)
            }
            {item?.price === 0 &&
              (<Chip label="FREE !" style={{ fontWeight: 'bold', backgroundColor: 'green', color: 'white' }} />)
            }
          </div>

          {productFormatWithCurrency(item)}

          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <a style={{ display: "inline-block", borderTopStyle: "solid" }}>
              &ensp;&ensp;&ensp;
            </a>
          </div>
          {item.availableSizes[0] !== 'standard' &&
            <div>
              <div style={{
                textAlign: "center", fontWeight: "400", margin: "0 0 0.5em",
                lineHeight: "1.4", fontFamily: "auto"
              }}>
                Available Sizes/Options</div>
              <div style={{
                textAlign: "center", fontWeight: "400", margin: "0 0 0.5em",
                lineHeight: "1.4", fontFamily: "auto"
              }}>
                {item.availableSizes.map((productSize, index) => {
                  if (index == selectedSizeIndex) {
                    return (
                      <span > <span style={{
                        border: "2px solid black",
                        padding: "5px",
                        cursor: "pointer"
                      }}
                        onClick={() => sizeSelectedPressed(productSize, index)}>
                        {productSize}</span>&nbsp;&nbsp;</span>
                    )
                  } else {
                    return (
                      <span > <span style={{
                        cursor: "pointer"
                      }}
                        onClick={() => sizeSelectedPressed(productSize, index)}>
                        {productSize}</span>&nbsp;&nbsp;</span>
                    )
                  }
                }
                )}
                {itemSizes}
              </div>
            </div>}

          <div style={{
            textAlign: "center", fontWeight: "400", margin: "0 0 0.5em",
            lineHeight: "1.4", fontFamily: "auto"
          }}>
            <Button variant="dark" onClick={() => addThisProductToCart(item)}>ADD TO CART</Button>{' '}

          </div>

          <div style={{
            textAlign: "center", fontWeight: "400", margin: "2em",
            lineHeight: "1.4", fontFamily: "auto"
          }}>
            {item.description.split('\\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </div>

          {item.additionalInformation &&
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>

              {Object.entries(item.additionalInformation).map(([key]) => (
                <Chip
                  key={key}
                  label={key}
                  variant="outlined"
                  onClick={() => handleClick(key)}
                  color={selectedKey === key ? 'primary' : 'default'}
                />
              ))}
              {selectedKey && (
                <div>
                  <p style={{ backgroundColor: 'lightgray', borderRadius: '25px', padding: '10px' }}>{item.additionalInformation[selectedKey]}</p>
                </div>
              )}
            </div>
          }
          <div style={{
            textAlign: "center", fontWeight: "400", margin: "2em",
            lineHeight: "1.4", fontFamily: "auto"
          }}>

            SHARE&ensp;

            <FacebookShareButton
              url={'https://ghummantech.com' + currentUrl}
              quote={item.title}

            >
              <ShareFacebookIcon size={32} round />
            </FacebookShareButton>&ensp;

            <TwitterShareButton
              url={'https://ghummantech.com' + currentUrl}
              title={item.title}

            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>

          </div>
        </div>
      )
    });
  }

  let showCarousel;
  if (props.products[0] != undefined) {
    showCarousel =
      <Carousel>
        {props.products[0].images.map((image) => (
          <Carousel.Item key={image}>
            <img
              className="d-block w-100"
              src={image}
              alt="First slide"
            />
          </Carousel.Item>
        ))}
      </Carousel>
  }

  return (
    <div id="outer-container">
      <HamburgerMenu isMenuOpenFunc={isMenuOpen} pageWrapId="page-wrap" outerContainerId="outer-container" isOpenStatus={menuHidden} history={props.history} callbackClose={callbackRoutingCloseMenu} />
      <div id="page-wrap">
        <Header openMenu={openMenuWindow} openCart={openFloatCart} signIn={signMeIn} history={props.history} />
        < div class="container">
          <div class="row justify-content-center">
            <div class="col-md-6">
              {showCarousel}
            </div>
            <div class="col-md-6" >
              {productDetails}
            </div>
          </div>
          <div class="row justify-content-center">
            {products[0]?.video_url && (
              <div style={{
                position: 'relative',
                width: '90vw',
                paddingTop: '56.25%', // for 16:9 aspect ratio
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '0', // This is important to include
                margin: '0 auto' // Center the container horizontally
              }}>
                <iframe
                  src={products[0]?.video_url}
                  frameBorder="0"
                  allowFullScreen
                  title="Chapter Video"
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%'
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <FloatCart isTheCartOpen={isOpen} history={props.history} />
        <Footer history={props.history} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => (
  {
    products: state.shelf.products,
    filters: state.filters.items,
    authenticate: state.authenticate,
    cartProducts: state.cart.products,
    newProduct: state.cart.productToAdd,
    productToRemove: state.cart.productToRemove,
    productToChange: state.cart.productToChange,
    cartTotal: state.total.data,
    currency: state.currency.data
  }
);

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) =>
    dispatch({ type: 'UPDATE', username: username, password: password }),
  signout: () => dispatch({ type: 'LOGOUT' }),
});

export default connect(
  mapStateToProps,
  { mapDispatchToProps, updateFilters, fetchProduct, addProduct }
)(Product);
