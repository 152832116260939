import React from 'react';
import { Link } from 'react-scroll'


const ServiceIntroductionLink = () => {
    return (
        <div className="homepage-container">
          <h1>Welcome to Our Services</h1>
          <p className="service-description">
            We are your one-stop destination for cutting-edge technological solutions. Our team specializes in a wide range of services to meet your digital needs. From crafting innovative mobile applications to developing robust web platforms, our expertise extends to the realms of <span className="keyword">App Development</span> and <span className="keyword">Web Development</span>. Dive into the Internet of Things (<span className="keyword">IOT</span>) with our tailored solutions that seamlessly integrate smart devices. Ensure the foundation of your digital presence with our <span className="keyword">Infrastructure & Cloud Setup</span> services, providing scalable and efficient solutions. In an era where security is paramount, our dedicated team is committed to safeguarding your digital assets through top-notch <span className="keyword">Cyber Security</span> measures. Embrace the future of technology with us!
          </p>
          <Link style={{ backgroundColor: "black", borderRadius: "25px", padding: "10px", color: "white", cursor: "pointer", margin: "20px" }} to="contact-form" spy={true} smooth={true} offset={50} duration={500}>
            Contact Us
          </Link>
        </div>
    )
}

export default ServiceIntroductionLink; 