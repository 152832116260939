import React from 'react';

const ExchangeRefundPolicy = () => (
  <div className="policy-section">
    <h2 className="title">Flexible Exchange &amp; Refund Policy</h2>
    <p className="intro">We prioritize your satisfaction, so here's our hassle-free approach to exchanges and refunds. Don't hesitate to reach out if you need assistance!</p>

    <section className="exchanges">
      <h3>Exchanges</h3>
      <ul>
        <li>Have concerns? You can exchange any unused or undamaged item within 30 days for a full refund or store credit.</li>
        <li>Just send us an email at <a href="mailto:admin@ghummantech.com">admin@ghummantech.com</a> with your order number and desired exchange item (if applicable).</li>
        <li>We'll handle everything! We'll send a return label and process your exchange promptly.</li>
        <li>Please note shipping costs for exchanged items are your responsibility.</li>
      </ul>
    </section>

    <section className="refunds">
      <h3>Refunds</h3>
      <ul>
        <li>Not completely satisfied? You have 14 days after receiving the item to request a full refund.</li>
        <li>Simply drop us an email at <a href="mailto:admin@ghummantech.com">admin@ghummantech.com</a> explaining your reason for return.</li>
        <li>We'll understand! We'll review your request and provide return instructions.</li>
        <li>Once the item arrives in its original condition, we'll issue a full refund to your original payment method.</li>
      </ul>
    </section>

    <section className="exceptions">
      <h3>Exceptions</h3>
      <p>Please note that this policy doesn't apply to:</p>
      <ul>
        <li>Items bought on sale or clearance.</li>
        <li>Personalized or customized items.</li>
        <li>Items that have been used or damaged.</li>
      </ul>
    </section>

    <p className="promise">We value your trust and believe in fairness. If you have any concerns, please don't hesitate to contact us. We're always happy to help!</p>
  </div>
);

export default ExchangeRefundPolicy;