import React, { useState, useEffect } from 'react';


import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';

import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './style.css';
const UserAgreement = (props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);
  const signMeIn = () => {
    navigate('/Login');
  };

  useEffect(() => {
    if (!menuHidden) {
      document.body.setAttribute("style", "overflow: hidden; position: static;");
    } else {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    }
    // Cleanup function to reset the body style when the component unmounts or when menuHidden changes again
    return () => {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    };
  }, [menuHidden]);

  useEffect(() => {
    document.body.setAttribute("style", "overflow: visible; position: static;");
  }, []);

  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };


  const isMenuOpen = (state) => {
    setMenuHidden(!state.isOpen);
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  return (
    <div id="outer-container">
      <HamburgerMenu isMenuOpenFunc={isMenuOpen} pageWrapId="page-wrap" outerContainerId="outer-container" isOpenStatus={menuHidden} history={props.history} callbackClose={callbackRoutingCloseMenu} />
      <div id="page-wrap">
        <Header openMenu={openMenuWindow} openCart={openFloatCart} signIn={signMeIn} history={props.history} />

        <FloatCart isTheCartOpen={isOpen} history={props.history} />


        <h1 style={{ textAlign: "center", fontFamily: "Old Standard TT" }}>Ghumman Tech User Agreement</h1>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <a style={{ display: "inline-block", borderTopStyle: "solid" }}>
            &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
          </a>
        </div>
        <div style={{ fontFamily: "serif", padding: "20px", textAlign: "center" }}>
              <p>
                Please read this User Agreement carefully before using the Ghumman Tech
                website or services. By using our website or services, you agree to be
                bound by the terms of this agreement.
              </p>

                <h2>1. Acceptance of Terms</h2>
                <p>
                  This User Agreement is a legally binding contract between you and
                  Ghumman Tech. By using our website or services, you agree to be bound
                  by the terms of this agreement. If you do not agree to these terms, you
                  may not use our website or services.
                </p>

                <h2>2. Definitions</h2>
                <ul>
                  <li>
                    "Website" means the Ghumman Tech website, located at
                    https://www.ghummantech.com.
                  </li>
                  <li>
                    "Services" means the services offered by Ghumman Tech through the
                    website, including seminats, software services, online products and others.
                  </li>
                  <li>
                    "User" means any person who accesses or uses the website or services.
                  </li>
                  {/* ... other definitions as needed */}
                </ul>

                <h2>3. Eligibility</h2>
                <p>
                  The website and services are intended for users who are 18 years of age
                  or older. By using the website or services, you represent and warrant
                  that you are at least 18 years old.
                </p>

                <h2>4. Registration and Account</h2>
                <p>
                  To access certain features of the website or services, you may be
                  required to register for an account. You agree to provide accurate and
                  complete information during the registration process and to keep your
                  account information updated. You are responsible for maintaining the
                  confidentiality of your account password and for all activities that
                  occur under your account.
                </p>
                <h2>5. User Conduct</h2>
                <p>
                  You agree to use the Ghumman Tech website and services in a manner that is
                  consistent with all applicable laws and regulations, and in a way that does
                  not infringe upon the rights of others. This includes, but is not limited to:
                </p>
                <ul>
                  <li>Not posting or transmitting any unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or pornographic content.</li>
                  <li>Not engaging in any discriminatory or hateful conduct.</li>
                  <li>Not attempting to gain unauthorized access to the website or services, or to the accounts of other users.</li>
                  <li>Not interfering with the operation of the website or services.</li>
                  <li>Not using the website or services for any commercial purpose without our express written consent.</li>
                  <li>Not misrepresenting yourself or your affiliation with any person or entity.</li>
                </ul>
                <p>We reserve the right to terminate your access to the website or services for any violation of these terms.</p>

                <h2>6. Content and Intellectual Property</h2>
                <p>
                  All content on the Ghumman Tech website, including text, graphics, logos, images, software, and other materials, is protected by copyright and other intellectual property laws. You may not reproduce, modify, distribute, or exploit any of this content without our express written permission.
                </p>
                <p>
                  Any content you submit to the website, including but not limited to comments, reviews, and feedback, becomes the property of Ghumman Tech. You grant us a non-exclusive, worldwide, royalty-free license to use, modify, publish, and distribute this content in any way we choose.
                </p>
                <p>
                  You are responsible for ensuring that any content you submit to the website is legal, decent, and does not infringe upon the rights of others. We reserve the right to remove any content that we deem to be inappropriate or unlawful.
                </p>

                <h2>7. Termination</h2>
                <p>
                  We may terminate your access to the website or services at any time, for any reason, without notice. You may also terminate your account at any time.
                </p>

                <h2>8. Disclaimer of Warranties</h2>
                <p>
                  The Ghumman Tech website and services are provided "as is" and without any warranties of any kind, express or implied. We disclaim all warranties, including, but not limited to, warranties of merchantability, fitness for a particular purpose, and non-infringement.
                </p>
                <p>
                  We do not warrant that the website or services will be uninterrupted, error-free, or secure. We do not warrant that the results obtained from the use of the website or services will be accurate or reliable.
                </p>

                <h2>9. Limitation of Liability</h2>
                <p>
                  To the maximum extent permitted by law, Ghumman Tech will not be liable for any damages arising out of your use of the website or services. This includes, but is not limited to, direct, indirect, incidental, consequential, and punitive damages.
                </p>

                <h2>10. Indemnification</h2>
                <p>
                  You agree to indemnify and hold harmless Ghumman Tech, its directors, officers, employees, agents, and partners from and against any and all claims, losses, damages, liabilities, costs, and expenses (including attorneys' fees) arising out of or related to your use of the website or services, or your violation of this User Agreement.
                </p>

                <h2>11. Dispute Resolution</h2>
                <p>
                  Any dispute arising out of or related to this User Agreement will be resolved by binding arbitration in accordance with the rules of the American Arbitration Association. The arbitration will be conducted in Houston, Texas, and the decision of the arbitrator will be final and binding.
                </p>

                <h2>12. Governing Law</h2>
                <p>
                  This User Agreement will be governed by and construed in accordance with the laws of the State of Texas, without regard to its conflict of laws provisions.
                </p>
        </div>

        <Footer history={props.history} />
      </div>
    </div>
  );

}

const mapStateToProps = (state) => (
  {
    authenticate: state.authenticate,
    cartProducts: state.cart.products,
    newProduct: state.cart.productToAdd,
    productToRemove: state.cart.productToRemove,
    productToChange: state.cart.productToChange,
    cartTotal: state.total.data
  })

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) =>
    dispatch({ type: 'UPDATE', username: username, password: password }),
  signout: () => dispatch({ type: 'LOGOUT' }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAgreement);
