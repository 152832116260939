import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { getBaseUrl } from '../Utils/getBaseUrl';
import Spinner from "../Spinner";
import UserInfo from "./UserInfo";
import CalendarHeatmap from 'react-calendar-heatmap';
import ReactTooltip from 'react-tooltip';

import 'react-calendar-heatmap/dist/styles.css';
import './style.css';


const EMAIL = "email";

const SettingsTabPanel = () => {
  const navigate = useNavigate();
  const [isMember, setIsMember] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userHeatMap, setUserHeatMap] = useState([]);


  const checkMembershipStatus = async (email) => {
    try {
      const baseUrl = getBaseUrl();
      const dataCheckSubscriptionStatus = {
        email: email,
      };

      const responseCheckSubscriptionStatus = await fetch(`${baseUrl}/api/check-subscription-status`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataCheckSubscriptionStatus),
      });
      const dataSubscriptionStatus = await responseCheckSubscriptionStatus.json();
      if (dataSubscriptionStatus.message === 'active') {
        setIsMember(true);
      } else {
        setIsMember(false);
      }
    } catch (e) {
      console.error('Error checking memberhsip details:', e);
    }
  };

  const getMemberHeatMap = async (email) => {
    try {
      const baseUrl = getBaseUrl();
      const dataGetMemberHeatMap = {
        user_email: email,
      };

      const responseGetMemberHeatMap = await fetch(`${baseUrl}/api/user-heat-map`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataGetMemberHeatMap),
      });
      if (responseGetMemberHeatMap.ok) {
        const dataHeatMap = await responseGetMemberHeatMap.json();
        setUserHeatMap(dataHeatMap);
      }
    } catch (e) {
      console.error('Error getting user heat map:', e);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const resultEmail = localStorage.getItem("email");
        if (resultEmail !== "") {
          await checkMembershipStatus(resultEmail);
          await getMemberHeatMap(resultEmail);
        } else {
          // User not signed in 
          navigate('/');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const signMeOut = async () => {
    const confirmation = window.confirm('Are you sure, you want to sign out?');
    if (!confirmation) {
      return;
    }
    localStorage.setItem(EMAIL, '');
    try {
    } catch (error) {
      // Handle error if necessary
    }
    navigate({
      pathname: '/',
    });
  };

  const cancelSubscription = async () => {
    const confirmation = window.confirm('Are you sure, you want to cancel your subscription?');
    if (!confirmation) {
      return;
    }
    try {
      const baseUrl = getBaseUrl();

      const dataRequest = {
        email: localStorage.getItem(EMAIL),
      };

      const response = await fetch(`${baseUrl}/api/cancel-subscription`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.message === 'success') {
          setIsMember(false);
        }
      }
    } catch (e) {
      console.error('Error cancelling subscription:', e);
    }
  }

  const endDate = new Date();
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 3);

  // Assuming userHeatMap is an array of objects with a 'count' property
  const maxCount = Array.isArray(userHeatMap) && userHeatMap.length > 0
    ? Math.max(...userHeatMap.map(value => value.count))
    : 0;

  // Define the ranges for the four color scales
  const range1 = maxCount / 4;
  const range2 = (maxCount / 4) * 2;
  const range3 = (maxCount / 4) * 3;

  const classForValue = (value) => {
    if (!value || value.count === 0) {
      return 'color-empty';
    } else if (value.count <= range1) {
      return 'color-scale-1';
    } else if (value.count <= range2) {
      return 'color-scale-2';
    } else if (value.count <= range3) {
      return 'color-scale-3';
    } else {
      return 'color-scale-4';
    }
  };

  return (
    <div>
      {isLoading && <Spinner />}
      <h2 className="upload-activity-title">Your Upload Activity Over the Last 3 Months</h2>
      <CalendarHeatmap
        id="my-anchor-element"
        startDate={startDate}
        endDate={endDate}
        values={userHeatMap}
        classForValue={classForValue}
        tooltipDataAttrs={value => {
          if (!value || !value.date) {
            return { 'data-tip': 'No data' };
          }
          const formattedDate = new Date(value.date).toLocaleDateString();
          const uploadText = value.count === 1 ? 'upload' : 'uploads';
          return {
            'data-tip': `${formattedDate}: ${value.count} ${uploadText}`,
          };
        }}
      />
      <ReactTooltip />
      <Container>
        <Row className="justify-content-center">
          <UserInfo />
          <div className="setting-buttons" onClick={signMeOut}>SIGN OUT</div>
          <div className="setting-buttons" onClick={() => navigate('/Change')}>CHANGE PASSWORD</div>
          {!isMember && <div className="setting-buttons" onClick={() => navigate('/BuyMembership')}>BECOME MEMBER</div>}
          {isMember && <div className="setting-buttons" onClick={cancelSubscription}>CANCEL SUBSCRIPTION</div>}
        </Row>
      </Container>
    </div>
  );
};

export default SettingsTabPanel;