import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';

const ProfileButton = () => {
  const [email, setEmail] = useState("");
  useEffect(() => {
    const resultEmail = localStorage.getItem("email");
    setEmail(resultEmail);
  }, []);
  const navigate = useNavigate();
  return (
    <div style={{marginTop: '10px'}}>
      <button 
        className='menuProfileButtonStyle'
        onClick={() => email ? navigate("/MyAccount") : navigate("/Login")}
      >
        User Profile
      </button>
    </div>
  );
};

export default ProfileButton;