import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Header from '../Header';
import FloatCart from '../FloatCart';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';
import './style.css';
import VideoWithButton2 from './VideoWithButton2';
import ImageWithButton from './ImageWithButton';
import SummerCampAd from './SummerCampAd';
import GhummanRoboAd from './GhummanRoboAd';
import CompanyDivisionCards from './CompanyDivisionCards';
import CompanyDivisionCards2 from './CompanyDivisionCards2';
import { useNavigate } from 'react-router-dom';
import WhatsAppAnnouncementGroup from './WhatsAppAnnouncementGroup';
import { scroller } from 'react-scroll'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';



const MainPage = (props) => {
  const isSubscribe = window.location.hash === '#subscribe';
  useEffect(() => {
    if (isSubscribe) {
      scroller.scrollTo('subscribe', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  }, [isSubscribe])
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);


  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };

  const signMeIn = () => {
    navigate('/Login');
  };

  const isMenuOpen = (state) => {
    if (state.isOpen === false) {
      setMenuHidden(true);
    } else {
      setMenuHidden(false);
    }
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: false,
    onChange: (oldIndex, newIndex) => {
    },
  };

  return (
    <div id="outer-container">

      <HamburgerMenu
        isMenuOpenFunc={isMenuOpen}
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        isOpenStatus={menuHidden}
        history={props.history}
        callbackClose={callbackRoutingCloseMenu}
      />
      <div id="page-wrap">
        <Header
          openMenu={openMenuWindow}
          openCart={openFloatCart}
          signIn={signMeIn}
          history={props.history}
        />
        <TawkMessengerReact
          propertyId="663396d5a0c6737bd133b152"
          widgetId="1hssoac5o" />
        <GhummanRoboAd />
        <SummerCampAd />
        <ImageWithButton />
        {/* <CompanyDivisionCards /> */}
        <CompanyDivisionCards2 />
        <WhatsAppAnnouncementGroup id="subscribe" />
        <VideoWithButton2 />
        <FloatCart isTheCartOpen={isOpen} history={props.history} />
        <Footer history={props.history} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authenticate: state.authenticate,
  cartProducts: state.cart.products,
  newProduct: state.cart.productToAdd,
  productToRemove: state.cart.productToRemove,
  productToChange: state.cart.productToChange,
  cartTotal: state.total.data,
});

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) =>
    dispatch({ type: 'UPDATE', username: username, password: password }),
  signout: () => dispatch({ type: 'LOGOUT' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
