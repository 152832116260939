import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import 'react-phone-number-input/style.css'

import { withAlert } from 'react-alert'

import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { connect } from 'react-redux';
import { loadCart, removeProduct, changeProductQuantity } from '../../services/cart/actions';
import { updateCart } from '../../services/total/actions';
import CartProduct from './CartProduct';
import { formatPrice } from '../../services/util';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'

import Divider from '@mui/material/Divider';

import CheckoutForm from './CheckoutForm';
import './style.css';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getBaseUrl } from '../Utils/getBaseUrl';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { useNavigate, useLocation } from 'react-router-dom';

import SubscriptionCheckboxes from '../CommonComponents/SubscriptionCheckboxes';


const stripePromise = process.env.REACT_APP_NOT_SECRET_CODE ?
    loadStripe('pk_test_4hmpKDMcjCeCwm8cvzr4ZYrv') :
    loadStripe('pk_live_zed2dWneRMXvc1gfUmwFU99t');


const clientId = process.env.REACT_APP_NOT_SECRET_CODE ?
    'AaqRauJRMhXufHghmDkMoi8wdZQxrnUWOatPhTXfwgC6wQaigkIyW5aki9DOm_uB_24MR1NKpzq-k-9d' :
    'ASu5ZETD_Z47u8oGvP_NgwPr5CtcgdYXqUauW267w4206BlaRmtzZzHencBt1pADeOsmB9TyUGcCull5';

const Checkout = (props) => {

    const navigate = useNavigate();
    const location = useLocation();

    const [previousState, setPreviousState] = useState(true);
    const [productAddedState, setProductAddedState] = useState(false);
    // const [specialInstructionsText, setSpecialInstructionsText] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [menuHidden, setMenuHidden] = useState(true);
    const [customer, setCustomer] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        address1: '',
        city: '',
        stateProvince: '',
        zip: '',
        country: 'UNITED STATES'
    });
    const [customerBook, setCustomerBook] = useState({
        name: '',
        parentName: '',
        emailBook: '',
        emailBookConfirm: '',
        phoneNumber: '',
        city: '',
        stateProvince: '',
        zip: '',
        country: '',
        subscribeWhatsapp: true,
        subscribeNewsletter: true,
    });
    const [gotCustomerInformation, setGotCustomerInformation] = useState(false);
    const [gotContinuePaymentConfirmation, setGotContinuePaymentConfirmation] = useState(false);
    const [shippingCost, setShippingCost] = useState(0);
    const [shippmentMethod, setShippmentMethod] = useState('card');
    // const [isPurchasingBook, setIsPurchasingBook] = useState(false);
    const [gotContinueBookPaymentConfirmation, setGotContinueBookPaymentConfirmation] = useState(false);


    useEffect(() => {
        if (!menuHidden) {
            document.body.setAttribute("style", "overflow: hidden; position: static;");
        } else {
            document.body.setAttribute("style", "overflow: visible; position: static;");
        }
        // Cleanup function to reset the body style when the component unmounts or when menuHidden changes again
        return () => {
            document.body.setAttribute("style", "overflow: visible; position: static;");
        };
    }, [menuHidden]);

    // useEffect(() => {

    //     if (props.cartProducts) {
    //         for (let i = 0; i < props.cartProducts.length; i++) {
    //             for (let j = 0; j < props.cartProducts[i].category.length; j++) {
    //                 if (props.cartProducts[i].category[j] === "books" || props.cartProducts[i].category[j] === "courses") {
    //                     setIsPurchasingBook(true);
    //                 }
    //             }
    //         }
    //     }

    //     document.body.setAttribute("style", "overflow: visible; position: static;");
    // }, []);

    useEffect(() => {
        if (props.newProduct !== props.newProduct) {
            addProduct(props.newProduct);
        }
    }, [props.newProduct]);

    useEffect(() => {
        if (props.productToRemove !== props.productToRemove) {
            removeProduct(props.productToRemove);
        }
    }, [props.productToRemove]);

    useEffect(() => {
        if (props.productToChange !== props.productToChange) {
            changeProductQuantity(props.productToChange);
        }
    }, [props.productToChange]);

    useEffect(() => {
        if (previousState !== props.isTheCartOpen) {
            setPreviousState(props.isTheCartOpen);
            setIsOpen(true);
            setProductAddedState(false);
        }
    }, [previousState, props.isTheCartOpen]);

    useEffect(() => {
        if (productAddedState) {
            setPreviousState(props.isTheCartOpen);
            setIsOpen(true);
            setProductAddedState(false);
        }
    }, [productAddedState, props.isTheCartOpen]);

    const openFloatCart = () => {
        setIsOpen(!isOpen);
    };

    const closeFloatCart = () => {
        setIsOpen(false);
        setProductAddedState(true);
    };

    const emptyCart = () => {
        console.log('inside empty cart');
        const { cartProducts, updateCart } = props;
        cartProducts.splice(0, cartProducts.length);
        updateCart(cartProducts);
    };

    const addProduct = () => {
        const { cartProducts, updateCart } = props;
        let productAlreadyInCart = false;
        const updatedCartProducts = cartProducts.map(cp => {
            if (cp.id === props.product.id && cp.selectedSize === props.product.selectedSize) {
                cp.quantity += props.product.quantity;
                productAlreadyInCart = true;
            }
            return cp;
        });

        if (!productAlreadyInCart) {
            updatedCartProducts.push(props.product);
        }

        updateCart(updatedCartProducts);
        setProductAddedState(true);
        openFloatCart();
    };
    const removeProduct = () => {
        const { cartProducts, updateCart } = props;
        const updatedCartProducts = cartProducts.filter(p => !(p.id === props.product.id && p.selectedSize === props.product.selectedSize));
        updateCart(updatedCartProducts);
    };

    const proceedToCheckout = () => {
        const {
            totalPrice,
            productQuantity,
            currencyFormat,
            currencyId
        } = props.cartTotal;

        if (!productQuantity) {
            alert('Add some product in the cart!');
        } else {
            alert(
                `Checkout - Subtotal: ${currencyFormat} ${formatPrice(
                    totalPrice,
                    currencyId
                )}`
            );
        }
    };

    const changeProductQuantity = changedProduct => {
        const { cartProducts, updateCart } = props;

        const product = cartProducts.find(p => p.id === changedProduct.id && p.selectedSize === changedProduct.selectedSize);
        product.quantity = changedProduct.quantity;
        if (product.quantity <= 0) {
            removeProduct(product);
        }
        updateCart(cartProducts);
    }

    const handleOrderRegistration = async (type_of_payment) => {

        console.log('location: ', location);
        const baseUrl = getBaseUrl();
        const dataRequest = {
            name: customerBook?.name.trim(),
            parent_name: customerBook?.parentName.trim(),
            email: customerBook?.emailBook.trim(),
            phone_number: customerBook?.phoneNumber.trim(),
            address: '',
            city: customerBook?.city.trim(),
            state: customerBook?.stateProvince.trim(),
            country: customerBook?.country.trim(),
            zip: customerBook?.zip.trim(),
            amount_paid: props.cartTotal.totalPrice + shippingCost,
            payment_type: type_of_payment,
            products_purchased: props.cartProducts,
            special_instructions: location?.state?.specialInstructions,
            subscribe_whatsapp: customerBook?.subscribeWhatsapp,
            subscribe_newsletter: customerBook?.subscribeNewsletter
        };

        const response = await fetch(`${baseUrl}/api/purchase-order/create`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dataRequest),
        });

        const data = await response.json();

        // check response status
        if (response.ok && data.message === 'Purchase order created successfully!') {
            props.alert.show('Your order is confirmed', {
                timeout: 2000, // custom timeout just for this one alert
                type: 'success',
                onClose: () => {
                    emptyCart();
                    navigate('/');
                }
            })
        } else {
            props.alert.show('Internal Server Error', {
                timeout: 2000, // custom timeout just for this one alert
                type: 'error'
            })
        }
        return;
    }

    const handleToken = async (token, addresses) => {
        let totalPaidAmout = "$ " + ((props.cartTotal.totalPrice + shippingCost)) + " USD";

        return await handleOrderRegistration('card');

    };

    const handleDeclinePayment = () => {
        props.alert.show('Payment Declined', {
            timeout: 2000, // custom timeout just for this one alert
            type: 'error'
        })
    }



    const handleEmailChange = event => {
        const val = event.target.value;
        setCustomer(prevState => ({
            ...prevState,
            email: val
        }));
    };

    const handleNameChangeBook = event => {
        const val = event.target.value;
        setCustomerBook(prevState => ({
            ...prevState,
            name: val
        }));
    };

    const handleParentNameChangeBook = event => {
        const val = event.target.value;
        setCustomerBook(prevState => ({
            ...prevState,
            parentName: val
        }));
    };

    const handlePhoneChangeBook = event => {
        const val = event.target.value;
        setCustomerBook(prevState => ({
            ...prevState,
            phoneNumber: val
        }));
    };

    const handleCityChangeBook = event => {
        const val = event.target.value;
        setCustomerBook(prevState => ({
            ...prevState,
            city: val
        }));
    };

    const handleStateChangeBook = event => {
        const val = event.target.value;
        setCustomerBook(prevState => ({
            ...prevState,
            stateProvince: val
        }));
    };
    const handleZipChangeBook = event => {
        const val = event.target.value;
        setCustomerBook(prevState => ({
            ...prevState,
            zip: val
        }));
    };

    const handleCountryChangeBook = event => {
        const val = event.target.value;
        setCustomerBook(prevState => ({
            ...prevState,
            country: val
        }));
    };


    const handleEmailChangeBook = event => {
        const val = event.target.value;
        setCustomerBook(prevState => ({
            ...prevState,
            emailBook: val
        }));
    };

    const handleEmailChangeBookConfirm = event => {
        const val = event.target.value;
        setCustomerBook(prevState => ({
            ...prevState,
            emailBookConfirm: val
        }));
    };

    const handlePhoneNumberChange = event => {
        const val = event.target.value;
        setCustomer(prevState => ({
            ...prevState,
            phoneNumber: val
        }));
    };

    const handleNameChange = event => {
        const val = event.target.value;
        setCustomer(prevState => ({
            ...prevState,
            name: val
        }));
    };

    const handleAddress1Change = event => {
        const val = event.target.value;
        setCustomer(prevState => ({
            ...prevState,
            address1: val
        }));
    };

    const handleCityChange = event => {
        const val = event.target.value;
        setCustomer(prevState => ({
            ...prevState,
            city: val
        }));
    };

    const handleStateChange = event => {
        const val = event.target.value;
        setCustomer(prevState => ({
            ...prevState,
            stateProvince: val
        }));
    };

    const handleZipChange = event => {
        const val = event.target.value;
        setCustomer(prevState => ({
            ...prevState,
            zip: val
        }));
    };

    const handleCountryChange = event => {
        const val = event.target.value;
        setCustomer(prevState => ({
            ...prevState,
            country: val
        }));
    };

    // const handleSpecialInstructionsChange = (event) => {
    //     setSpecialInstructionsText(event.target.value);
    // };

    const handleSubmitCustomerData = (e) => {
        e.preventDefault();
        setGotCustomerInformation(true);
        setShippingCost(5);
    };


    const handleSubmitBookEmail = (e) => {
        e.preventDefault();
        if (!customerBook.emailBook || !customerBook.emailBookConfirm) {
            notifyEmptyEmailBook();
            setGotContinueBookPaymentConfirmation(false);
        } else if (customerBook.emailBook !== customerBook.emailBookConfirm) {
            notifySameEmailBook();
            setGotContinueBookPaymentConfirmation(false);
        } else {
            setGotContinueBookPaymentConfirmation(true);
        }
    };

    const handleChangeCustomerInformation = () => {
        setGotCustomerInformation(false);
    };


    const isMenuOpen = (state) => {
        if (state.isOpen === false) {
            setMenuHidden(true);
        } else {
            setMenuHidden(false);
        }
        return state.isOpen;
    };



    const signMeIn = () => {
        navigate('/Login');
    };

    const openMenuWindow = () => {
        setMenuHidden(false);
    };

    const handleContinuePayment = () => {
        setGotContinuePaymentConfirmation(true);
    };

    const handleShippmentMethodChange = (event) => {
        const val = event.target.value;
        setShippmentMethod(val);
    };

    const callbackRoutingCloseMenu = () => {
        setMenuHidden(true);
    };

    const notifyEmptyEmailBook = () => {
        toast("Email should be present");
    };

    const notifySameEmailBook = () => {
        toast("Emails are not same");
    };

    const createOrder = async (data, actions) => {
        try {
            const orderID = await actions.order.create({
                purchase_units: [
                    {
                        description: "Purchasing Product: " + props.cartProducts.map((p) => p.name).join(", "),
                        amount: {
                            currency_code: "USD",
                            value: props.cartTotal.totalPrice + shippingCost,
                        },
                    },
                ],
            });

            //   setOrderID(orderID);
            return orderID;
        } catch (error) {
            console.error("Error creating order:", error);
            throw error; // Re-throw the error for higher-level handling
        }
    }

    const { cartTotal, cartProducts, currency } = props;

    const products = cartProducts.map(p => {
        return (
            <CartProduct product={p} removeProduct={props.removeProduct} changeProductQuantity={props.changeProductQuantity} key={p.id} currency={currency} />
        );
    });

    let classes = ['float-cart'];

    if (!!isOpen) {
        classes.push('float-cart--open');
    }

    let checkout;

    if (gotCustomerInformation && gotContinuePaymentConfirmation) {
        if (shippmentMethod === "paypal") {
            checkout = (
                <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                    <PayPalScriptProvider options={{ "client-id": clientId }}>

                        <PayPalButtons
                            style={{ layout: "vertical" }}
                            createOrder={(data, actions) => createOrder(data, actions)}
                            onApprove={() => handleOrderRegistration('paypal')}
                        />
                    </PayPalScriptProvider>
                </div>
            )
        }
        else if (shippmentMethod === "card") {
            let checkCurrency = "USD";
            let checkAmount = (props.cartTotal.totalPrice + shippingCost);

            checkout = (<div style={{ marginTop: "20px", marginBottom: "10px" }}>
                <Elements stripe={stripePromise}>
                    <CheckoutForm
                        finalAmount={checkAmount}
                        finalCurrency={checkCurrency}
                        history={props.history}
                        finalEmptyCart={emptyCart}
                        finalHandleToken={handleToken}
                        finalDeclinePayment={handleDeclinePayment}
                        name={customer.name.trim()}
                        email={customer.email.trim()}
                        phone_number={customer.phoneNumber.trim()} />
                </Elements>
            </div>)
        }
    }

    let checkoutBook;

    if (gotContinueBookPaymentConfirmation) {
        const checkCurrency = "USD";
        const checkAmount = props.cartTotal.totalPrice;
        checkoutBook = (<div style={{ marginTop: "20px", marginBottom: "10px" }}>
            <Elements stripe={stripePromise}>
                <CheckoutForm
                    finalAmount={checkAmount}
                    finalCurrency={checkCurrency}
                    history={props.history}
                    finalEmptyCart={emptyCart}
                    finalHandleToken={handleToken}
                    finalDeclinePayment={handleDeclinePayment}
                    email={customerBook.emailBook.trim()}
                    name={'NA'}
                    phone_number={'NA'} />
            </Elements>
        </div>)
    } else {
        checkoutBook = null;
    }

    let productFormatWithCurrencySubTotal = (
        <p className="sub-price__val">
            $ <span> </span>{cartTotal.totalPrice}
        </p>
    )

    let productFormatWithCurrencyShippment = (
        <p className="sub-price__val">
            {gotCustomerInformation && <span>$
                <span> </span>
                {shippingCost}</span>}
        </p>
    )


    let productFormatWithCurrencyTotal = (
        <p className="sub-price__val">
            $<span> </span>
            {(cartTotal.totalPrice + shippingCost)}
        </p>
    )
    const standardShippmentCurrency = (
        <div style={{ marginRight: "3px" }}>
            $ 5
        </div>
    )
    return (
        <div id="outer-container">
            <HamburgerMenu isMenuOpenFunc={isMenuOpen} pageWrapId="page-wrap" outerContainerId="outer-container" isOpenStatus={menuHidden} history={props.history} callbackClose={callbackRoutingCloseMenu} />
            <div id="page-wrap">
                <Header openMenu={openMenuWindow} openCart={openFloatCart} signIn={signMeIn} history={props.history} />

                <div style={{
                    textAlign: "center", fontWeight: "400", margin: "0 0 0.5em",
                    lineHeight: "1.4", fontFamily: "auto", fontSize: "40px"
                }}>
                    CHECKOUT
                </div>

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <a style={{ display: "inline-block", borderTopStyle: "solid" }}>
                        &ensp;&ensp;&ensp;
                    </a>
                </div>
                <Container>
                    <Row>
                        <Col sm={8}>
                            {/* {!isPurchasingBook && !gotCustomerInformation && <Form onSubmit={handleSubmitCustomerData}>
                                <Row>
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            placeholder="Enter email"
                                            required
                                            value={customer.email}
                                            onChange={handleEmailChange} />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridPhoneNumber">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Phone Number"
                                            required
                                            value={customer.phoneNumber}
                                            onChange={handlePhoneNumberChange} />
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} controlId="formGridName">
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter name"
                                            required
                                            value={customer.name}
                                            onChange={handleNameChange} />
                                    </Form.Group>
                                </Row>

                                <Form.Group controlId="formGridAddress1">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        placeholder="340 Sierra Rd"
                                        required
                                        value={customer.address1}
                                        onChange={handleAddress1Change} />
                                </Form.Group>

                                <Row>
                                    <Form.Group as={Col} controlId="formGridCity" >
                                        <Form.Label >City</Form.Label>
                                        <Form.Control
                                            required
                                            value={customer.city}
                                            onChange={handleCityChange} />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridState">
                                        <Form.Label>Province or State</Form.Label>
                                        <Form.Control
                                            required
                                            value={customer.stateProvince}
                                            onChange={handleStateChange} />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridZip">
                                        <Form.Label>Postal or Zip Code</Form.Label>
                                        <Form.Control
                                            placeholder="72216"
                                            value={customer.zip}
                                            onChange={handleZipChange} />
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} controlId="formGridState">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control as="select"
                                            value={customer.country}
                                            onChange={handleCountryChange}
                                        >
                                            <option>UNITED STATES</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Row>

                                <div style={{ marginBottom: "10px" }}>
                                    <Button variant="primary" type="submit">
                                        Continue to shipping
                                    </Button>
                                </div>
                            </Form>} */}

                            {gotCustomerInformation &&
                                <div style={{ border: "3px solid gray", borderRadius: "5px", padding: "3px", fontFamily: "Times New Roman" }}>
                                    <div style={{ fontSize: "18px" }}>Contact&ensp;&ensp;&ensp;{customer.email}&ensp;&ensp;&ensp; <span style={{ color: "#c7ac7f", cursor: "pointer", fontSize: "15px" }} onClick={() => handleChangeCustomerInformation()}>CHANGE</span></div>
                                    <Divider />
                                    <div style={{ fontSize: "18px" }}>Ship to&ensp;&ensp;&ensp; {customer.address1} {','}&ensp;{customer.city}&ensp;{customer.stateProvince}&ensp;{customer.zip}{','}&ensp;{customer.country}</div>
                                </div>}

                            {gotCustomerInformation &&
                                <div style={{
                                    marginTop: "20px", border: "3px solid gray", borderRadius: "5px", padding: "3px", fontFamily: "Times New Roman", fontSize: "18px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center"

                                }}>
                                    <div style={{ marginLeft: "3px" }}>

                                        <Radio
                                            checked={true}
                                            name="radio-button-demo"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />&ensp; Standard Shipping

                                    </div>
                                    {standardShippmentCurrency}

                                </div>}

                            {gotCustomerInformation &&
                                !gotContinuePaymentConfirmation &&
                                <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                                    <Button
                                        variant="primary"
                                        onClick={() => handleContinuePayment()}>
                                        Continue to payment
                                    </Button>
                                </div>
                            }


                            {gotCustomerInformation && gotContinuePaymentConfirmation &&
                                <div style={{
                                    marginTop: "20px", border: "3px solid gray", borderRadius: "5px", padding: "3px", fontFamily: "Times New Roman", fontSize: "18px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center"

                                }}>
                                    <div style={{ marginLeft: "3px" }}>
                                        <div>

                                            <FormControl component="fieldset" className={classes.formControl}>
                                                <FormLabel component="legend" style={{ fontFamily: "Times New Roman", color: "black" }}>PAYMENT METHOD</FormLabel>
                                                <RadioGroup
                                                    aria-label="Gender"
                                                    name="gender1"
                                                    className={classes.group}
                                                    value={shippmentMethod}
                                                    onChange={handleShippmentMethodChange}
                                                >
                                                    <FormControlLabel style={{ fontFamily: "Times New Roman", color: "black" }} value="card" control={<Radio />} label="Credit/Debit Card" />
                                                    <FormControlLabel style={{ fontFamily: "Times New Roman", color: "black" }} value="paypal" control={<Radio />} label="Paypal" />
                                                </RadioGroup>
                                            </FormControl>


                                        </div>

                                    </div>

                                </div>
                            }
                            {checkout}
                            <Form onSubmit={handleSubmitBookEmail} style={{ backgroundColor: "white" }}>
                                <Row>
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <Form.Label>Student Name</Form.Label>
                                        <Form.Control
                                            placeholder="Enter Studnet/s Name/s"
                                            type="text"
                                            maxLength={50}
                                            value={customerBook.name}
                                            onChange={handleNameChangeBook}
                                            style={{ backgroundColor: 'white' }} />
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <Form.Label>Parent Name</Form.Label>
                                        <Form.Control
                                            placeholder="Parent Name in case student is minor"
                                            type="text"
                                            maxLength={50}
                                            value={customerBook.parentName}
                                            onChange={handleParentNameChangeBook}
                                            style={{ backgroundColor: 'white' }} />
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control
                                            placeholder="Enter phone number"
                                            type="tel"
                                            maxLength={50}
                                            value={customerBook.phoneNumber}
                                            onChange={handlePhoneChangeBook}
                                            style={{ backgroundColor: 'white' }} />
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                            placeholder="Enter city"
                                            type="text"
                                            maxLength={50}
                                            value={customerBook.city}
                                            onChange={handleCityChangeBook}
                                            style={{ backgroundColor: 'white' }} />
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label>State</Form.Label>
                                            <Form.Control
                                                placeholder="Enter state"
                                                type="text"
                                                maxLength={50}
                                                value={customerBook.stateProvince}
                                                onChange={handleStateChangeBook}
                                                style={{ backgroundColor: 'white' }} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label>Zip Code</Form.Label>
                                            <Form.Control
                                                placeholder="Enter Zip Code"
                                                type="number"
                                                maxLength={50}
                                                value={customerBook.zip}
                                                onChange={handleZipChangeBook}
                                                style={{ backgroundColor: 'white' }} />
                                        </Form.Group>
                                    </Col>

                                </Row>
                                <Row>
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control
                                            placeholder="Enter country"
                                            type="text"
                                            maxLength={50}
                                            value={customerBook.country}
                                            onChange={handleCountryChangeBook}
                                            style={{ backgroundColor: 'white' }} />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <Form.Label style={{ color: 'red' }}>Email*</Form.Label>
                                        <Form.Control
                                            placeholder="Enter email"
                                            type="email"
                                            required
                                            maxLength={50}
                                            value={customerBook.emailBook}
                                            onChange={handleEmailChangeBook}
                                            style={{ backgroundColor: 'white' }} />
                                    </Form.Group>

                                </Row>

                                <Row>
                                    <Form.Group as={Col} controlId="formGridEmailConfirm">
                                        <Form.Label style={{ color: 'red' }}>Confirm Email*</Form.Label>
                                        <Form.Control
                                            placeholder="Enter email (again)"
                                            type="email"
                                            required
                                            maxLength={50}
                                            value={customerBook.emailBookConfirm}
                                            onChange={handleEmailChangeBookConfirm}
                                            style={{ backgroundColor: 'white' }} />
                                    </Form.Group>

                                </Row>
                                <SubscriptionCheckboxes formData={customerBook} setFormData={setCustomerBook} />
                                <div style={{ marginBottom: "10px" }}>
                                    <Button variant="primary" type="submit">
                                        Confirm Email and Continue to Payment
                                    </Button>
                                </div>
                            </Form>
                            {checkoutBook}



                        </Col>
                        <Col sm={4}>
                            <div>
                                {products}
                            </div>
                            <div style={{ backgroundColor: "white" }}>
                                {/* <div className="sub">TOTAL</div>
                                <div className="sub-price">
                                    {productFormatWithCurrencySubTotal}


                                </div> */}

                                {/* <div className="sub">SHIPPING</div>
                                <div className="sub-price">
                                    {productFormatWithCurrencyShippment}


                                </div> */}

                                <Divider />

                                <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.5em' }}>TOTAL</div>
                                <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.5em' }}>
                                    {productFormatWithCurrencyTotal}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>


                <Footer history={props.history} />
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    cartProducts: state.cart.products,
    newProduct: state.cart.productToAdd,
    productToRemove: state.cart.productToRemove,
    productToChange: state.cart.productToChange,
    cartTotal: state.total.data,
    currency: state.currency.data
});

export default withAlert()(connect(
    mapStateToProps,
    { loadCart, updateCart, removeProduct, changeProductQuantity }
)(Checkout));
