import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
// import Image from '@mui/material/Image';
// import { Image } from 'react-bootstrap';
import { FaYoutube, FaFacebook, FaInstagram, FaPinterest } from 'react-icons/fa';
import { FaSquareXTwitter } from "react-icons/fa6";
import PaypalPicture from '../../assets/Paypal-Logo.png'
import cardsPicture from '../../assets/cards.png'
import './style.css';
// import { withRouter } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { youtubeLink, facebookLink, instagramLink, twitterLink, pintrestLink } from './../Utils/constants'

const Header = () => { 
  const navigate = useNavigate();
  const fbOpen = () => {
    window.open('https://www.facebook.com/GhummanTech/');
  };

  const instaOpen = () => {
    window.open('https://www.instagram.com/ederracouture/');
  };

  const privacyOpen = () => {
    navigate('/Privacy');
  };

  const userAgreementOpen = () => {
    navigate('/UserAgreement');
  };

  const exchangeOpen = () => {
    navigate('/Exchange');
  };

  const sizeChartOpen = () => {
    navigate('/SizeChart');
  };

  const contactOpen = () => {
    navigate('/ContactUs');
    // window.open('https://www.facebook.com/pg/SabaJC/about/?ref=page_internal');
  };

  const aboutOpen = () => {
    navigate('/AboutUs');
    // window.open('https://www.facebook.com/pg/SabaJC/about/?ref=page_internal');
  };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <a style={{ display: "inline-block", borderTopStyle: "solid" }}>
          &ensp;&ensp;&ensp;
        </a>
      </div>
      <Box
        display="flex"
        justifyContent="space-between"
        p={1}
        m={1}
      >


        <Box p={1}>

          <div style={{ fontFamily: "Georgia", cursor: "pointer" }} onClick={() => exchangeOpen()}>Exchanges & Refunds</div>
          <div style={{ fontFamily: "Georgia", cursor: "pointer" }} onClick={() => privacyOpen()}>Privacy Policy</div>
          <div style={{ fontFamily: "Georgia", cursor: "pointer" }} onClick={() => userAgreementOpen()}>User Agreement</div>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          p={1}
          m={1}
        >

        <Box p={1}>
          {/* <div style={{ cursor: "pointer" }} onClick={() => fbOpen()}><FacebookIcon fontSize="small" /> <span style={{ fontFamily: "Georgia", padding: "2px" }}>FACEBOOK</span></div> */}
          {/* <div style={{ cursor: "pointer" }} onClick={() => instaOpen()}><InstagramIcon fontSize="small" /> <span style={{ fontFamily: "Georgia", padding: "2px" }}>INSTAGRAM</span></div> */}
          <a href={youtubeLink} target="_blank" rel="noopener noreferrer">
            <FaYoutube style={{ color: 'red', margin: '5px' }} />
          </a>
          <a href={facebookLink} target="_blank" rel="noopener noreferrer">
            <FaFacebook style={{ color: 'blue', margin: '5px' }} />
          </a>
          <a href={instagramLink} target="_blank" rel="noopener noreferrer">
            <FaInstagram style={{ color: 'purple', margin: '5px' }} />
          </a>
          <a href={twitterLink} target="_blank" rel="noopener noreferrer">
            <FaSquareXTwitter style={{ color: "black", margin: '5px' }} />
          </a>
          <a href={pintrestLink} target="_blank" rel="noopener noreferrer">
            <FaPinterest style={{ color: "red", margin: '5px' }} />
          </a>
        </Box>
        <Box p={1}>
          &copy; {new Date().getFullYear()} Ghumman Tech 
        </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          p={1}
          m={1}
        >
          <Box p={1}>
            {/* <Image src={cardsPicture} rounded bsPrefix="objectFit: cover"/> */}
            {/* <div style={{backgroundImage: `url(${PakistanFlag})`}}></div> */}
            {/* <img alt="Pakistan Flag" src={PakistanFlag} style={{ backgroundSize: "50px 20px" }} /> */}
            {/* <Image
              src={cardsPicture}
              width={200}
              height={40}
              thumbnail
            /> */}
                {/* <Image
      src={cardsPicture}
      alt="Cards image"
      rounded // Add the "rounded" prop for a thumbnail effect
      fluid // Add the "fluid" prop for responsive image display (optional)
      width={200}
      height={40}
    /> */}
                {/* <img
                  src={cardsPicture}
                  alt="Cards image"
                  style={{ width: 200, height: 40, objectFit: 'cover' }} // Apply styles
                /> */}

            <div style={{ fontFamily: "Georgia", cursor: "pointer" }} onClick={() => contactOpen()}>Contact us</div>
            <div style={{ fontFamily: "Georgia", cursor: "pointer" }} onClick={() => aboutOpen()}>About us</div>
          
          </Box>
          {/* <Box p={1}>
            <img
              src={PaypalPicture}
              style={{ width: 100, height: 40}}
            />
          </Box> */}
        </Box>
      </Box>

    </div>
  );
}
export default Header;
