import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';

const GhummanRoboTrialButton = () => {
  const navigate = useNavigate();
  return (
    <div>
      <button 
        className='menuButtonStyle'
        onClick={() => navigate('/GhummanRoboTrial')}
      >
        Book Trial Class
      </button>
    </div>
  );
};

export default GhummanRoboTrialButton;