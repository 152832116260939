import React, {useEffect, useState} from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom'; // For routing to AboutUs page

const VideoWithButton2 = ({ videoSrc = 'https://github.com/ghumman/public-small-videos/blob/main/GhummanTechMainPageVideo.mp4', buttonText = 'EXPLORE' }) => {
  const [fontSize, setFontSize] = useState('200%');
  const isDesktopOrLarger = useMediaQuery({ minWidth: 768 });

  return (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
        <video autoPlay muted loop style={{ width: isDesktopOrLarger ? '90vw' : '90vw', height: isDesktopOrLarger ? '' : '45vw', objectFit: 'contain' }}>
            <source src="https://github.com/ghumman/public-small-videos/blob/main/GhummanTechMainPageVideo.mp4?raw=true" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        <div style={{ position: 'absolute', zIndex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: isDesktopOrLarger ? '50vw' : '80vw' }}>        <Link to="/Categories/courses" className="video-button">
          {'Courses'}
        </Link>
        <Link to="/BuyMembership" className="video-button" style={{backgroundColor: 'white', color: 'red'}}>
          {'Become Member'}
        </Link>
        <Link to="/Categories" className="video-button">
          {buttonText}
        </Link>
        </div>
    </div>
  );
};

export default VideoWithButton2;