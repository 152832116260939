import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./style.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getBaseUrl } from '../Utils/getBaseUrl';
import { useNavigate } from 'react-router-dom';

import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';

const PASSWORD = "password";

const Change = (props) => {
  const navigate = useNavigate();

  const [errorCurrentPassword, setErrorCurrentPassword] = useState("");
  const [errorServerMessage, setErrorServerMessage] = useState("");
  const [globalEmail, setGlobalEmail] = useState("");
  const [formCurrentPassword, setFormCurrentPassword] = useState("");
  const [formPassword, setFormPassword] = useState("");
  const [formPassword2, setFormPassword2] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);


  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };

  const signMeIn = () => {
    navigate('/Login');
  };

  const isMenuOpen = (state) => {
    if (state.isOpen === false) {
      setMenuHidden(true);
    } else {
      setMenuHidden(false);
    }
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  const handleChangeCurrentPassword = (event) => {
    setFormCurrentPassword(event.target.value);
  };

  const handleChangePassword = (event) => {
    setFormPassword(event.target.value);
  };

  const handleChangePassword2 = (event) => {
    setFormPassword2(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const password = formPassword.trim();
    const password2 = formPassword2.trim();
    const currentPassword = formCurrentPassword.trim();

    if (password.length < 5 || password !== password2) {
      setErrorServerMessage('Password should be 5 characters long and match.');
      return; // Early return on failed validation
    }

    try {
      const response = await fetch(`${getBaseUrl()}/api/change-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: globalEmail,
          oldPassword: currentPassword,
          newPassword: password,
        }),
      });

      const data = await response.json();

      if (response.ok && data.message === "Password changed successfully") {
        setErrorServerMessage("Password changed successfully.");
        localStorage.setItem(PASSWORD, password); // Update stored password after successful change
      } else {
        setErrorServerMessage(data.error);
      }
    } catch (error) {
      console.error(error); // Handle potential fetch/json parsing errors
    }
  };

  useEffect(() => {
    const resultEmail = localStorage.getItem("email") || "";
    if (resultEmail) {
      setGlobalEmail(resultEmail);
    } else {
      props.login(resultEmail, null);
      navigate({ pathname: "/" });
    }
  }, []);

  const loginPressed = () => {
    navigate({
      pathname: "/"
    });
  }

  const { loggingIn } = props;

  var errorMessageCurrent;
  if (errorCurrentPassword != "") {
    errorMessageCurrent = (
      <p
        style={{
          paddingTop: 20,
          color: "red"
        }}
      >
        {errorCurrentPassword}
      </p>
    );
  } else {
    errorMessageCurrent = null;
  }

  var errorMessage;
  if (errorServerMessage != "") {
    errorMessage = (
      <p
        style={{
          paddingTop: 20,
          color: "red"
        }}
      >
        {errorServerMessage}
      </p>
    );
  } else {
    errorMessage = null;
  }
  return (
    <div id="outer-container">
      <HamburgerMenu
        isMenuOpenFunc={isMenuOpen}
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        isOpenStatus={menuHidden}
        history={props.history}
        callbackClose={callbackRoutingCloseMenu}
      />
      <div id="page-wrap">
        <Header
          openMenu={openMenuWindow}
          openCart={openFloatCart}
          signIn={signMeIn}
          history={props.history}
        />
        <div className="App">
          <header className="App-header">
            <p style={{ textDecorationLine: 'underline' }}>Welcome to Ghumman Tech</p>
            <p
              style={{
                borderStyle: "solid",
                borderColor: "black",
                borderRadius: 5,
                padding: 5
              }}
            >
              Change Password
            </p>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="loginFormCurrentPassword">
                <Form.Label>Current Password</Form.Label>
                <Form.Control
                  type="password"
                  value={formCurrentPassword}
                  placeholder="Current Password"
                  onChange={handleChangeCurrentPassword}
                />
                {errorMessageCurrent}
              </Form.Group>

              <Form.Group controlId="loginFormPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={formPassword}
                  placeholder="Password"
                  onChange={handleChangePassword}
                />
              </Form.Group>

              <Form.Group controlId="loginFormPassword2">
                <Form.Label>Password (again)</Form.Label>
                <Form.Control
                  type="password"
                  value={formPassword2}
                  placeholder="Password"
                  onChange={handleChangePassword2}
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                RESET PASSWORD
              </Button>
            </Form>

            <div>
              <p
                style={{ fontSize: 14, paddingTop: 20 }}
                onClick={loginPressed}
              >
                Back / Cancel
              </p>
            </div>

            {errorMessage}
          </header>
        </div>
        <FloatCart isTheCartOpen={isOpen} history={props.history} />
        <Footer history={props.history} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => (
  {
    authenticate: state
  })

const mapDispatchToProps = (dispatch) => ({
  login: (email, password) =>
    dispatch({ type: "UPDATE", email: email, password: password }),
  signout: () => dispatch({ type: 'LOGOUT' }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Change);
