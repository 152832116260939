import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchProducts } from '../../services/shelf/actions';
import { updateFilters } from '../../services/filters/actions';
import { updateFiltersCategory } from '../../services/filtersCategory/actions';

import Spinner from '../Spinner';
import ShelfHeader from './ShelfHeader';
import ProductList from './ProductList';

import './style.css';

class ShelfCollection extends Component {
  static propTypes = {
    fetchProducts: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    filters: PropTypes.array,
    filtersCategory: PropTypes.array,
    sort: PropTypes.string,
    history: PropTypes.object.isRequired,
    categoryName: PropTypes.string.isRequired,
    updateFilters: PropTypes.func.isRequired,
    updateFiltersCategory: PropTypes.func.isRequired,
  };

  state = {
    isLoading: false,
    filteredProducts: null
  };

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    const { filters: nextFilters, filtersCategory: nextFiltersCategory, sort: nextSort } = nextProps;
    const { filters } = this.props;
    if (nextFilters !== filters) {
      this.handleFetchProducts(nextFilters, undefined, undefined);
    }

    const { filtersCategory } = this.props;
    if (nextFiltersCategory.length !== filtersCategory.length) {
      this.handleFetchProducts(undefined, nextFiltersCategory, undefined);
    }

    if (nextSort !== this.props.sort) {
      this.handleFetchProducts(undefined, undefined, nextSort);
    }
  }

  handleFetchProducts = (
    filters = this.props.filters,
    filtersCategory = this.props.filtersCategory,
    sort = this.props.sort
  ) => {
    this.setState({ isLoading: true });
    this.props.fetchProducts(filters, filtersCategory, sort, () => {
      this.setState({ isLoading: false });
    });
  };

  render() {
    const { products } = this.props;
    const { isLoading } = this.state;

    return (
      <React.Fragment>
        {isLoading && <Spinner />}
        <div className="shelf-container">
          <ShelfHeader productsLength={products.length} />
          <ProductList products={products} history={this.props.history} currency={this.props.currency}/>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  products: state.shelf.products,
  filters: state.filters.items,
  filtersCategory: state.filtersCategory.items,
  sort: state.sort.type,
  currency: state.currency.data
});

export default connect(
  mapStateToProps,
  { fetchProducts, updateFilters, updateFiltersCategory  }
)(ShelfCollection);
