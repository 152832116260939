import React, { useState, useEffect } from 'react';
import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';
import { connect } from 'react-redux';
import './style.css';
import { useNavigate } from 'react-router-dom';
const Privacy = (props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);
  const signMeIn = () => {
    navigate('/Login');
  };

  useEffect(() => {
    if (!menuHidden) {
      document.body.setAttribute("style", "overflow: hidden; position: static;");
    } else {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    }
    // Cleanup function to reset the body style when the component unmounts or when menuHidden changes again
    return () => {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    };
  }, [menuHidden]);

  useEffect(() => {
    document.body.setAttribute("style", "overflow: visible; position: static;");
  }, []);

  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };


  const isMenuOpen = (state) => {
    setMenuHidden(!state.isOpen);
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  return (
    <div id="outer-container">
      <HamburgerMenu isMenuOpenFunc={isMenuOpen} pageWrapId="page-wrap" outerContainerId="outer-container" isOpenStatus={menuHidden} history={props.history} callbackClose={callbackRoutingCloseMenu} />
      <div id="page-wrap">
        <Header openMenu={openMenuWindow} openCart={openFloatCart} signIn={signMeIn} history={props.history} />

        <FloatCart isTheCartOpen={isOpen} history={props.history} />


        <h1 style={{ textAlign: "center", fontFamily: "Old Standard TT" }}>Ghumman Tech Privacy Policy</h1>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <a style={{ display: "inline-block", borderTopStyle: "solid" }}>
            &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
          </a>
        </div>
        <div style={{ fontFamily: "serif", padding: "20px", textAlign: "center" }}>
                <p>
                  At Ghumman Tech, we understand the importance of your privacy and are
                  committed to protecting your personal information. This policy explains
                  what information we collect, how we use it, and the choices you have
                  about your data.
                </p>

                <h2>Information We Collect</h2>
                <ul>
                  <li>
                    <strong>Personally Identifiable Information (PII)</strong>: This
                    includes your name, address, contact number(s), email address,
                    payment preferences, and financial information such as credit/debit
                    card numbers and bank account details. We collect this information
                    when you register, place an order, contact customer service, or
                    participate in promotional activities.
                  </li>
                  <li>
                    <strong>Non-Personally Identifiable Information (NPII)</strong>: This
                    includes information that does not directly identify you, such as
                    browsing history, IP address, device type, and operating system. We
                    collect this information automatically through cookies and other
                    tracking technologies to improve your website experience and
                    personalize your browsing.
                  </li>
                </ul>

                <h2>How We Use Your Information</h2>
                <ul>
                  <li>Fulfilling your orders and requests</li>
                  <li>Improving your website experience</li>
                  <li>Marketing and communication (with the option to opt out)</li>
                </ul>

                <h2>Information Sharing and Disclosure</h2>
                <p>
                  We will not share your PII with any third-party entities for marketing
                  purposes without your express consent. However, we may share your
                  information with trusted service providers who assist us in operating
                  our website and fulfilling your orders. These service providers are
                  contractually obligated to protect your information and use it only for
                  the purposes authorized by Ghumman Tech.
                </p>
                <p>
                  We may disclose your information if required by law or to comply with a
                  legal process, such as a court order or subpoena. We may also disclose
                  your information if we believe it necessary to protect the safety and
                  security of others or to prevent fraud or abuse.
                </p>

                <h2>Your Choices and Control</h2>
                <ul>
                  <li>Accessing, updating, or deleting your PII</li>
                  <li>Controlling the use of cookies and other tracking technologies</li>
                </ul>

                <h2>Data Security</h2>
                <p>
                  We take reasonable measures to protect your information from unauthorized
                  access, disclosure, alteration, or destruction. This includes using
                  encryption technology, secure servers, and access controls. However, no
                  method of transmission over the internet or method of electronic storage
                  is 100% secure. Therefore, we cannot guarantee the absolute security of
                  your information.
                </p>

                <h2>Changes to this Policy</h2>
                <p>
                  We may update this privacy policy from time to time. We will notify you
                  of any changes by posting the new policy on our website. You are advised
                  to review this policy regularly for any updates.
                </p>

                <h2>Contact Us</h2>
                <p>
                  If you have any questions about this privacy policy, please contact us
                  at admin@ghummantech.com.
                </p>
        </div>

        <Footer history={props.history} />
      </div>
    </div>
  );

}
const mapStateToProps = (state) => (
  {
    authenticate: state.authenticate,
    cartProducts: state.cart.products,
    newProduct: state.cart.productToAdd,
    productToRemove: state.cart.productToRemove,
    productToChange: state.cart.productToChange,
    cartTotal: state.total.data
  })

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) =>
    dispatch({ type: 'UPDATE', username: username, password: password }),
  signout: () => dispatch({ type: 'LOGOUT' }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Privacy);
