import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import maleIcon from './../../assets/maleIcon.png';
import femaleIcon from './../../assets/femaleIcon.png';


const TeamInfo = () => {
    return (
        <div>            <h2>Our Team</h2>
        <Row>
        <Col xs={6} md={3} className="d-flex justify-content-center">
                <Card border="success" style={{height: '400px', width: '150px', margin: '2px'}}>
                    <Card.Img variant="top" src={maleIcon} style={{height: '200px'}}/>
                    <Card.Body style={{ textAlign: 'center' }}>
                    <Col>
                    <Row style={{display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold'}}>
                        Ahmed Ghumman
                    </Row>
                    <Row style={{display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold'}}>
                        Founder
                    </Row>
                    </Col>
                    </Card.Body>
                </Card>
            </Col>
            {/* <Col>
                <Card border="success" style={{height: '400px', width: '150px', margin: '2px'}}>
                    <Card.Img variant="top" src={femaleIcon} style={{height: '200px'}}/>
                    <Card.Body style={{ textAlign: 'center' }}>
                    <Col>
                    <Row style={{display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold'}}>
                        Marrium Ghumman
                    </Row>
                    <Row style={{display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold'}}>
                        Developer
                    </Row>
                    </Col>
                    </Card.Body>
                </Card>
            </Col> */}
    <Col xs={6} md={3} className="d-flex justify-content-center">
                <Card border="success" style={{height: '400px', width: '150px', margin: '2px'}}>
                    <Card.Img variant="top" src={maleIcon} style={{height: '200px'}}/>
                    <Card.Body style={{ textAlign: 'center' }}>
                    <Col>
                    <Row style={{display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold'}}>
                        Usama Ghumman
                    </Row>
                    <Row style={{display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold'}}>
                        Developer
                    </Row>
                    </Col>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={6} md={3} className="d-flex justify-content-center">
                <Card border="success" style={{height: '400px', width: '150px', margin: '2px'}}>
                    <Card.Img variant="top" src={maleIcon} style={{height: '200px'}}/>
                    <Card.Body style={{ textAlign: 'center' }}>
                    <Col>
                    <Row style={{display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold'}}>
                        Ibrahim Ghumman
                    </Row>
                    <Row style={{display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold'}}>
                        Developer
                    </Row>
                    </Col>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={6} md={3} className="d-flex justify-content-center">
                <Card border="success" style={{height: '400px', width: '150px', margin: '2px'}}>
                    <Card.Img variant="top" src={maleIcon} style={{height: '200px'}}/>
                    <Card.Body style={{ textAlign: 'center' }}>
                    <Col>
                    <Row style={{display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold'}}>
                        Zayd Ghumman
                    </Row>
                    <Row style={{display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold'}}>
                        Developer
                    </Row>
                    </Col>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        </div>
    )
}

export default TeamInfo; 