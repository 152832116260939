import React from 'react';
import { Checkbox } from '@mui/material';

const SubscriptionCheckboxes = ({ formData, setFormData }) => (
  <div style={{ marginBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
    <div style={{ flex: 1 }}>
      <Checkbox
        checked={formData.subscribeWhatsapp}
        onChange={(e) => setFormData({ ...formData, subscribeWhatsapp: e.target.checked })}
        color="primary"
      />
      <span>Subscribe to Ghumman Tech WhatsApp Community Group</span>
    </div>
    <div style={{ flex: 1 }}>
      <Checkbox
        checked={formData.subscribeNewsletter}
        onChange={(e) => setFormData({ ...formData, subscribeNewsletter: e.target.checked })}
        color="primary"
      />
      <span>Subscribe to Ghumman Tech Email Newsletter</span>
    </div>
  </div>
);

export default SubscriptionCheckboxes;