import React from 'react';
import { FaEnvelope, FaPhoneAlt, FaMapMarkerAlt, FaYoutube, FaFacebook, FaInstagram, FaPinterest } from 'react-icons/fa';
import { FaSquareXTwitter } from "react-icons/fa6";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


const ContactPage = () => {
    const LATITUDE = 29.552315;
    const LONGITUDE = -95.236097;
  return (
    <div className="contact-page">
      <Row>
      <h1 className="title">Get in Touch</h1>
      </Row>
      <Row>
        <Col>
        <div className="info-card">
          <FaEnvelope className="icon" />
          <p>Email: <a href="mailto:admin@ghummantech.com">admin@ghummantech.com</a></p>
        </div>
        </Col>
        <Col>
        <div className="info-card">
          <FaPhoneAlt className="icon" />
          <p>Phone: <a href="tel:14194058850">419-405-8850</a></p>
        </div>
        </Col>
        <Col>
        <div className="info-card">
          <FaMapMarkerAlt className="icon" />
          <p>Address: 19446 Bold River Rd, Tomball, TX, 77375</p>
        </div>
        </Col>
      </Row>
      <section className="social-media">
        <h2>Follow Us</h2>
        <ul>
          <li>
            <a href="https://www.youtube.com/@ghummantech" target="_blank" rel="noopener noreferrer">
              <FaYoutube style={{color: 'red'}}/> YouTube
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/ghummantech" target="_blank" rel="noopener noreferrer">
              <FaFacebook style={{color: 'blue'}}/> Facebook
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/ghummantech/" target="_blank" rel="noopener noreferrer">
              <FaInstagram style={{color: 'purple'}}/> Instagram
            </a>
          </li>
          <li>
            <a href="https://twitter.com/ghummantech" target="_blank" rel="noopener noreferrer">
              <FaSquareXTwitter style={{ color: "black" }}/> Twitter
            </a>
          </li>
          <li>
            <a href="https://www.pinterest.com/ghummantech/" target="_blank" rel="noopener noreferrer">
              <FaPinterest style={{color: "red"}}/> Pinterest
            </a>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default ContactPage;
