import React, { useState, useRef } from 'react';
import { Element } from 'react-scroll'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { getBaseUrl } from '../Utils/getBaseUrl';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

const ServiceLinkElement = () => {

  const [customer, setCustomer] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    company: '',
    projectDetails: '',
  });
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const recaptchaRef = useRef(null);

  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setCustomer({ ...customer, email: event.target.value });
  };

  const handlePhoneNumberChange = (event) => {
    setCustomer({ ...customer, phoneNumber: event.target.value });
  };

  const handleNameChange = (event) => {
    setCustomer({ ...customer, name: event.target.value });
  };

  const handleCompanyChange = (event) => {
    setCustomer({ ...customer, company: event.target.value });
  };

  const handleProjectDetailsChange = (event) => {
    setCustomer({ ...customer, projectDetails: event.target.value });
  };

  const handleSubmitCustomerData = async (event) => {
    event.preventDefault();

    try {
      // Verify reCAPTCHA before submitting
      if (!captchaVerified) {
        await recaptchaRef.current.executeAsync();
      }
      const baseUrl = getBaseUrl();

      const dataRequest = {
        name: customer.name.trim(),
        email: customer.email.trim(),
        phone_number: customer.phoneNumber.trim(),
        company: customer.company.trim(),
        project_details: customer.projectDetails.trim(),
      };

      const response = await fetch(`${baseUrl}/api/register-service-request`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });

      const data = await response.json();
      navigate('/Thankyou');
    } catch (error) {
      console.error('Error verifying reCAPTCHA:', error);
    }
  };


  return (
    <Element name="contact-form" className="element">
      <div style={{ textAlign: 'center', padding: '20px', textDecoration: 'underline' }}>
        <p className="platform-title">Contact Us</p>
      </div>

      <div className="row justify-content-center" style={{}}>
        <div style={{ fontSize: '20px', marginRight: '20px', marginLeft: '20px'}}>
          <Form onSubmit={handleSubmitCustomerData}>
            <Row>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  required
                  value={customer.email}
                  onChange={handleEmailChange}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPhoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Phone Number"
                  required
                  value={customer.phoneNumber}
                  onChange={handlePhoneNumberChange}
                />
              </Form.Group>
            </Row>

            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                placeholder="Enter name"
                required
                value={customer.name}
                onChange={handleNameChange}
              />
            </Form.Group>

            <Form.Group controlId="formGridCompany">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                placeholder="Your Company Name"
                required
                value={customer.company}
                onChange={handleCompanyChange}
              />
            </Form.Group>

            <Form.Group controlId="formGridProjectDetails">
              <Form.Label>Project Details</Form.Label>
              <Form.Control
                placeholder="Project Details"
                value={customer.projectDetails}
                onChange={handleProjectDetailsChange}
              />
            </Form.Group>

            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Lf98EYpAAAAAI42RS-0yTOrmHG8bQs7n24Y39qc" // Replace with your reCAPTCHA site key
              size="invisible" // Optional: make reCAPTCHA invisible
              onChange={() => setCaptchaVerified(true)}
            />

            <div style={{ marginBottom: '10px' }}>
              <Button variant="primary" type="submit">
                SEND
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Element>
  );
};

export default ServiceLinkElement; 