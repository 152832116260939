import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Thumb from './../../Thumb';

const CartProduct = ({ product, removeProduct, changeProductQuantity, currency }) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isBookInCart, setIsBookInCart] = useState(false);

  useEffect(() => {
    if (product.category) {
      product.category.forEach(category => {
        if (category === "books") {
          setIsBookInCart(true);
        }
      });
    }
  }, [product.category]);

  const handleMouseOver = () => {
    setIsMouseOver(true);
  };

  const handleMouseOut = () => {
    setIsMouseOver(false);
  };

  const handleOnIncrease = () => {
    product.quantity = product.quantity + 1;
    changeProductQuantity(product);
  };

  const handleOnDecrease = () => {
    product.quantity = product.quantity - 1;
    changeProductQuantity(product);
  };

  const classes = [];
  if (isMouseOver) {
    classes.push('shelf-item--mouseover');
  }

  const productFormatWithCurrency = (
    <p>$ <span> {product.price}</span></p>
  );

  return (
    <div className={classes.join(' ')} style={{margin: '10px'}}>
      <div
        className="shelf-item__del"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={() => removeProduct(product)}
      />
      <Thumb
        classes="shelf-item__thumb"
        src={product.images[0]}
        alt={product.title}
      />
      <div className="shelf-item__details">
        <p className="title">{product.title}</p>
        <p className="title" style={{ fontSize: '15px' }}>Quantity: {product.quantity}</p>
      </div>

      <div className="shelf-item__price" style={{marginTop: '35px'}}>
        {productFormatWithCurrency}
        {/* <div>
          <button onClick={handleOnDecrease} disabled={product.quantity === 0} className="change-product-button">-</button>
          <button onClick={handleOnIncrease} disabled={isBookInCart} className="change-product-button">+</button>
        </div> */}
      </div>
    </div>
  );
};

CartProduct.propTypes = {
  product: PropTypes.object.isRequired,
  removeProduct: PropTypes.func.isRequired,
  changeProductQuantity: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
};

export default CartProduct;
