import { FETCH_PRODUCTS, FETCH_PRODUCT } from './actionTypes';

import { getBaseUrl } from '../../components/Utils/getBaseUrl';

const compare = {
  lowestprice: (a, b) => {
    if (a.price < b.price) return -1;
    if (a.price > b.price) return 1;
    return 0;
  },
  highestprice: (a, b) => {
    if (a.price > b.price) return -1;
    if (a.price < b.price) return 1;
    return 0;
  }
};

export const fetchProduct = (sku) => async dispatch => {
  try {
    const baseUrl = getBaseUrl();
    const dataRequest = {
      sku: sku
    };

    const response = await fetch(`${baseUrl}/api/product`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataRequest),
    });

    const data = await response.json();
    dispatch({
      type: FETCH_PRODUCT,
      payload: [data],
    });
  } catch (error) {
    // Handle error
  }
};

export const fetchProducts = (filters, filtersCategory, sortBy, callback) => async dispatch => {
  try {
    const baseUrl = getBaseUrl();
    const dataRequest = {
      page: 1, // Default page
      category: filters && filters.category && filters.category.length === 1 ? filters.category[0] : '', // Pass filtersCategory if available, otherwise, empty string
      search: '', // Empty search query initially
    };

    const response = await fetch(`${baseUrl}/api/products`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataRequest),
    });

    const data = await response.json();
    let { products } = data;

    try {
      if (!!filters && filters.hasOwnProperty('sku')) {
        products = products.filter(p => p.sku === filters.sku);
      }
    } catch (e) {}

    if (!!sortBy) {
      products = products.sort(compare[sortBy]);
    }

    if (!!callback) {
      callback();
    }

    dispatch({
      type: FETCH_PRODUCTS,
      payload: products,
    });
  } catch (error) {
    // Handle error
  }
};