import React, { useState } from 'react';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import PropTypes from 'prop-types';
import { getBaseUrl } from '../Utils/getBaseUrl';
import { useNavigate } from 'react-router-dom';

const CheckoutForm = ({
  stripe,
  elements,
  finalEmptyCart,
  finalHandleToken,
  finalDeclinePayment,
  history,
  finalCurrency,
  finalAmount,
  name,
  email,
  phone_number,
}) => {
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState("");
  const [buttonDisableStatus, setButtonDisableStatus] = useState(false);

  const handleSubmit = async (event) => {
    setButtonDisableStatus(true);
    event.preventDefault();

    if (finalAmount === 0) {
      await finalHandleToken();
      // finalEmptyCart();
      setButtonDisableStatus(false);
      // navigate('/');
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    try {
      const baseUrl = getBaseUrl();
      const dataRequest = {
        amount: finalAmount,
        currency: 'USD'
      };

      const response = await fetch(`${baseUrl}/api/stripe-secret`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });
      const data = await response.json();

      setClientSecret(data.clientSecret);

      const result = await stripe.confirmCardPayment(data.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: name,
            email: email,
            phone: phone_number
          },
        }
      });

      if (result.error) {
        finalDeclinePayment();
        setButtonDisableStatus(false);
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          await finalHandleToken();
          // finalEmptyCart();
          setButtonDisableStatus(false);
          // navigate('/');
        }
      }
    } catch (error) {
      setButtonDisableStatus(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {finalAmount > 0 && (
        <CardSection />
      )}
      <button
        className="btn btn-primary"
        disabled={buttonDisableStatus}
      >
        Confirm Order
      </button>
    </form>
  );
};

CheckoutForm.propTypes = {
  stripe: PropTypes.object.isRequired,
  elements: PropTypes.object.isRequired,
  finalEmptyCart: PropTypes.func.isRequired,
  finalHandleToken: PropTypes.func.isRequired,
  finalDeclinePayment: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  finalCurrency: PropTypes.string.isRequired,
  finalAmount: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone_number: PropTypes.string.isRequired
};

const InjectedCheckoutForm = (props) => {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          stripe={stripe}
          elements={elements}
          finalAmount={props.finalAmount}
          finalCurrency={props.finalCurrency}
          history={props.history}
          finalEmptyCart={props.finalEmptyCart}
          finalHandleToken={props.finalHandleToken}
          finalDeclinePayment={props.finalDeclinePayment}
          name={props.name}
          email={props.email}
          phone_number={props.phone_number}
        />
      )}
    </ElementsConsumer>
  );
};

export default InjectedCheckoutForm;