import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getBaseUrl } from '../Utils/getBaseUrl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from '../Spinner';
import CourseTabs from './CourseTabs';
import LanguageSelector from './LanguageSelector';
import Chip from '@mui/material/Chip';

function CourseDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [eligible, setEligible] = useState(false);
  const [courseData, setCourseData] = useState(null);
  const [chapterContent, setChapterContent] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(1);
  const [userLanguage, setUserLanguage] = useState(localStorage.getItem("user-language"));
  const [isUserLanguageSelected, setIsUserLanguageSelected] = useState(localStorage.getItem("user-language") ? true : false);
  const [userLanguageFileData, setUserLanguageFileData] = useState(null);
  const [learnLanguageFileData, setLearnLanguageFileData] = useState(null);
  const [appFileData, setAppFileData] = useState(null);
  const [quizFileData, setQuizFileData] = useState(null);
  const [isLanugageCourseLoaded, setIsLanguageCourseLoaded] = useState(false);

  const email = localStorage.getItem("email");

  const checkIfEligible = async () => {
    try {

      const baseUrl = getBaseUrl();
      const dataRequest = {
        user_email: email,
        course_id: id
      };
      const response = await fetch(`${baseUrl}/api/check-user-course-eligibility`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });
      const data = await response.json();
      setEligible(data.eligible);
      if (!data.eligible) {
        navigate(`/`);
      }
    } catch (error) {
      console.error('Error checking course eligibility:', error);
    }
  };

  const checkSubscriptionStatus = async () => {
    try {
      const baseUrl = getBaseUrl();
      const dataRequest = {
        email: email,
      };
      const response = await fetch(`${baseUrl}/api/check-subscription-status`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.message === 'active') {
          setEligible(true);
          return true;
        }
        return false;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {

    const checkEligibility = async () => {
      const isMember = await checkSubscriptionStatus();
      if (!isMember) {
        await checkIfEligible();
      }
    };

    checkEligibility();
  }, []);

  useEffect(() => {
    const getQuizContent = async () => {
      try {
        setIsLoading(true);

        if (!selectedChapter) {
          setSelectedChapter(1);
        }
        if (userLanguageFileData && learnLanguageFileData && courseData?.type === 'language') {
          const chapter = userLanguageFileData.data.find(chapter =>
            selectedChapter ? chapter.customChapterNumber === selectedChapter : chapter.customChapterNumber === 1
          );
          if (chapter) {
            setChapterContent({
              course_id: id,
              name: chapter.customChapterName,
              chapter_number: selectedChapter,
              study_text_link: null,
              video_link: null,
              course_language: courseData.name.split(' ')[1].toLowerCase(),
            });
          }

          // Get the bookmark data for this course
          const baseUrl = getBaseUrl();
          const dataRequest = {
            user_email: email,
            course_id: id,
          };
          const allBookmarksForCourseResponse = await fetch(`${baseUrl}/api/all-bookmark-questions-for-course`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dataRequest),
          });

          if (allBookmarksForCourseResponse.ok) {
            const allBookmarksForCourseData = await allBookmarksForCourseResponse.json();
            console.log('allBookmarksForCourseData:', allBookmarksForCourseData);
            const newQuizData = userLanguageFileData.data
              .filter(chapter => chapter.customChapterNumber === selectedChapter)
              .map(chapter => {
                const correspondingChapter = learnLanguageFileData.data.find(
                  learnChapter => learnChapter.customFlashcardNumber === chapter.customFlashcardNumber
                );
                return {
                  course_id: id,
                  chapter_id: selectedChapter,
                  question: correspondingChapter ? correspondingChapter.slideAnswer : chapter.slideAnswer,
                  answer: chapter.slideAnswer,
                  option1: chapter.slideWrongOption1,
                  option2: chapter.slideWrongOption2,
                  option3: chapter.slideWrongOption3,
                  id: chapter.customFlashcardNumber,
                  bookmarked: allBookmarksForCourseData.includes(chapter.customFlashcardNumber) ? 1 : 0,
                };
              });
            setQuizFileData(newQuizData);
          }


        }
      } catch (error) {
        console.error('Error setting up bookmark:', error);
      } finally {
        setIsLoading(false);
      }

    }
    getQuizContent();

  }, [userLanguageFileData, learnLanguageFileData, selectedChapter, courseData]);

  useEffect(() => {
    const getAppQuizContent = async () => {
      try {
        setIsLoading(true);

        if (!selectedChapter) {
          setSelectedChapter(1);
        }
        if (appFileData && (courseData?.type === 'app' || courseData?.type === 'live')) {
          const chapter = appFileData.data.find(chapter =>
            selectedChapter ? chapter.customChapterNumber === selectedChapter : chapter.customChapterNumber === 1
          );
          if (chapter) {
            const chapterNumber = selectedChapter || 1;
            setChapterContent({
              course_id: id,
              name: chapter.customChapterName,
              chapter_number: chapterNumber,
              study_text_link: appFileData[`chapter${chapterNumber}Text`],
              isFileCourse: true,
              video_link: appFileData[`chapter${chapterNumber}Video`],
              course_language: courseData.name.split(' ')[1].toLowerCase(),
              meeting_details: appFileData[`meetingDetails`],
            });
          }

          // Get the bookmark data for this course
          const baseUrl = getBaseUrl();
          const dataRequest = {
            user_email: email,
            course_id: id,
          };
          const allBookmarksForCourseResponse = await fetch(`${baseUrl}/api/all-bookmark-questions-for-course`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dataRequest),
          });

          if (allBookmarksForCourseResponse.ok) {
            const allBookmarksForCourseData = await allBookmarksForCourseResponse.json();
            console.log('allBookmarksForCourseData:', allBookmarksForCourseData);
            const newQuizData = appFileData.data
              .filter(chapter => chapter.customChapterNumber === selectedChapter)
              .map(chapter => {
                return {
                  course_id: id,
                  chapter_id: selectedChapter,
                  question: chapter.slideQuestion,
                  question_language: chapter?.slideQuestionLanguage,
                  answer: chapter.slideAnswer,
                  answer_language: chapter?.slideAnswerLanguage,
                  option1: chapter.slideWrongOption1,
                  option1_language: chapter?.slideWrongOption1Language,
                  option2: chapter.slideWrongOption2,
                  option2_language: chapter?.slideWrongOption2Language,
                  option3: chapter.slideWrongOption3,
                  option3_language: chapter?.slideWrongOption3Language,
                  id: chapter.customFlashcardNumber,
                  bookmarked: allBookmarksForCourseData.includes(chapter.customFlashcardNumber) ? 1 : 0,
                  attachments: chapter.slideAttachments,
                  course_type: courseData?.type,
                };
              });
            setQuizFileData(newQuizData, courseData);
          }


        }
      } catch (error) {
        console.error('Error setting up bookmark:', error);
      } finally {
        setIsLoading(false);
      }

    }

    getAppQuizContent();

  }, [appFileData, selectedChapter, courseData]);


  const handleChapterClick = async (chapterNumber) => {
    setSelectedChapter(chapterNumber); // Update selected chapter
    if (courseData.type === 'programming') {
      try {
        const baseUrl = getBaseUrl();
        const dataRequest = {
          course_id: id,
          chapter_number: chapterNumber
        };
        const response = await fetch(`${baseUrl}/api/chapter`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataRequest),
        });
        const data = await response.json();
        setChapterContent(data);
      } catch (error) {
        console.error('Error fetching chapter content:', error);
      }
    }

  }

  useEffect(() => {

    const getUserLanguageFile = async () => {
      try {
        const baseUrl = getBaseUrl();
        const dataRequest = {
          pdfFileName: `spoken-languages/${userLanguage}-flashcards.json`,
        };
        const response = await fetch(`${baseUrl}/api/book-url`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataRequest),
        });

        if (response.ok) {
          const responseData = await response.json();
          // Fetch the content from the presigned URL
          const responseFromPresignedUrl = await fetch(responseData.presignedUrl);
          if (responseFromPresignedUrl.ok) {
            const content = await responseFromPresignedUrl.json();

            setUserLanguageFileData(content);
          }
        }
      } catch (error) {
        console.error('Error fetching user language file:', error);
      }
    };

    const getLearnLanguageFile = async () => {
      try {
        const language = courseData?.name.split(' ')[1].toLowerCase();

        const baseUrl = getBaseUrl();
        const dataRequest = {
          pdfFileName: 'spoken-languages/' + language + '-flashcards.json'
        };
        const response = await fetch(`${baseUrl}/api/book-url`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataRequest),
        });

        if (response.ok) {
          const responseData = await response.json();
          // Fetch the content from the presigned URL
          const responseFromPresignedUrl = await fetch(responseData.presignedUrl);
          if (responseFromPresignedUrl.ok) {
            const content = await responseFromPresignedUrl.json();

            setLearnLanguageFileData(content);
          }
        }
      } catch (error) {
        console.error('Error fetching user language file:', error);
      }
    };

    const getCourseFile = async () => {
      try {
        const url = new URL(courseData?.app_data_url);
        const fileLocation = url.pathname.substring(1); // Remove the leading '/'

        const baseUrl = getBaseUrl();
        const dataRequest = {
          pdfFileName: fileLocation
        };
        const response = await fetch(`${baseUrl}/api/book-url`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataRequest),
        });

        if (response.ok) {
          const responseData = await response.json();
          // Fetch the content from the presigned URL
          const responseFromPresignedUrl = await fetch(responseData.presignedUrl);
          if (responseFromPresignedUrl.ok) {
            const content = await responseFromPresignedUrl.json();

            // setLearnLanguageFileData(content);
            setAppFileData(content);
          }
        }
      } catch (error) {
        console.error('Error fetching user language file:', error);
      }
    };

    if (courseData && courseData.type === 'language' && userLanguage) {
      const prevUserLanguage = localStorage.getItem("user-language");
      if (userLanguage !== prevUserLanguage) {
        localStorage.setItem("user-language", userLanguage);
        getUserLanguageFile();
      }
      if (!isLanugageCourseLoaded) {
        getLearnLanguageFile();
        getUserLanguageFile();
        setIsLanguageCourseLoaded(true);
      }
    } else if (courseData && courseData.type === 'programming') {
      setIsUserLanguageSelected(true);
      handleChapterClick(selectedChapter);
    } else if (courseData && (courseData.type === 'app' || courseData.type === 'live')) {
      setIsUserLanguageSelected(true);
      getCourseFile();
    }

  }, [userLanguage, courseData]);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const baseUrl = getBaseUrl();
        const dataRequest = {
          course_id: id
        };
        const response = await fetch(`${baseUrl}/api/course`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataRequest),
        });

        const data = await response.json();
        setCourseData(data);
        if (data.type === 'programming') {
          await handleChapterClick(selectedChapter || 1);
        }
      } catch (error) {
        console.error('Error fetching course details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (eligible) {
      fetchCourseDetails();
    }
  }, [eligible, selectedChapter]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!eligible || !courseData) {
    return <p>No course details available.</p>;
  }

  function getGridSize(width) {
    if (width > 992) { // Adjust breakpoint as needed
      return 8;
    } else {
      return 4;
    }
  }


  return (
    <>
      {isUserLanguageSelected &&
        <div>
          {courseData.type === 'language' &&
            <div style={{ display: 'flex', justifyContent: 'end', marginRight: '20px' }}>
              <div className="title">User Language:</div>
              <Chip
                label={userLanguage.charAt(0).toUpperCase() + userLanguage.slice(1)}
                color="primary"
                onClick={() => setIsUserLanguageSelected(false)}
              />
            </div>
          }
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
            <h1>{courseData.name}</h1>
          </div>
          <div style={{ marginTop: '20px', marginLeft: '-20px' }}>
            <Row className="justify-content-center">
              {Array.from({
                length: (courseData.type === 'programming' || courseData.type === 'language')
                  ?
                  courseData?.number_of_chapters :
                  appFileData?.totalChapters
              }, (_, index) => (
                <Col xs={3} sm={3} lg={1} key={index} style={{ padding: '10px' }}>
                  <div
                    style={{
                      position: 'relative',
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      backgroundColor: selectedChapter === index + 1 ? 'green' : 'blue',
                      color: 'white',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      margin: 'auto', // Center the circle
                      '@media (max-width: 576px)': {
                        width: '30px',
                        height: '30px',
                      },
                    }}
                    onClick={() => handleChapterClick(index + 1)}
                  >
                    {index + 1}
                  </div>
                </Col>
              ))}
            </Row>
          </div>

          {chapterContent && (
            <div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h2>Chapter {selectedChapter}</h2>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h2>{chapterContent.name}</h2>
              </div>
              <CourseTabs chapterContent={chapterContent} quizFileData={quizFileData} />
            </div>
          )}
        </div>
      }
      {!isUserLanguageSelected && courseData.type === 'language' &&
        <LanguageSelector
          userLanguage={userLanguage}
          setUserLanguage={setUserLanguage}
          courseLanguage={courseData?.name.split(' ')[1].toLowerCase()}
          setIsUserLanguageSelected={setIsUserLanguageSelected}
        />}
    </>
  );
}


export default CourseDetails;