import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Thumb from './../../Thumb';

const CartProduct = ({ product, changeProductQuantity }) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseOver = () => {
    setIsMouseOver(true);
  };

  const handleMouseOut = () => {
    setIsMouseOver(false);
  };

  const classes = ['shelf-item'];
  if (isMouseOver) {
    classes.push('shelf-item--mouseover');
  }

  const productFormatWithCurrency = (
    <p>
      $ <span> </span>
      {product.price}
    </p>
  );

  return (
    <>
    <div className={classes.join(' ')} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      <Thumb classes="shelf-item__thumb" src={product.images[0]} alt={product.title} />

    </div>
    <div>
    <div className="shelf-item__details">
        <div>
        <p className="title" style={{ color: 'black' }}>
          {product.title}
        </p>
        </div>
        <div>
        <p className="title" style={{ fontSize: '15px', color: 'black' }}>
          Quantity: {product.quantity}
        </p>
        </div>
      </div>
      <div className="shelf-item__price">{productFormatWithCurrency}</div>

    </div>
    </>
  );
};

CartProduct.propTypes = {
  product: PropTypes.object.isRequired,
  removeProduct: PropTypes.func.isRequired,
  changeProductQuantity: PropTypes.func.isRequired,
};

export default CartProduct;
