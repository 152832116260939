import React, { useEffect, useState, useRef } from 'react';

import { connect } from 'react-redux';
import { loadCart, removeProduct, changeProductQuantity } from '../../services/cart/actions';
import { updateCart } from '../../services/total/actions';
import CartProduct from './CartProduct';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';

import './style.css';

const FloatCart = (props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [previousState, setPreviousState] = useState(true);
  const [productAddedState, setProductAddedState] = useState(false);
  const [specialInstructionsText, setSpecialInstructionsText] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  const prevProps = useRef(props);

  useEffect(() => {
    if (prevProps.current.newProduct !== props.newProduct) {
      addProduct(props.newProduct);
    }

    if (prevProps.current.productToRemove !== props.productToRemove) {
      removeProduct(props.productToRemove);
    }

    if (prevProps.current.productToChange !== props.productToChange) {
      changeProductQuantity(props.productToChange);
    }

    if (prevProps.current.isTheCartOpen !== props.isTheCartOpen) {
      setPreviousState(props.isTheCartOpen);
      setIsOpen(true);
      setProductAddedState(false);
    }

    if (productAddedState) {
      setPreviousState(props.isTheCartOpen);
      setIsOpen(true);
      setProductAddedState(false);
    }

    prevProps.current = props;
  }, [props]);

  const goToCheckoutPage = () => {
    navigate(
      '/Checkout',
      {state: {
        specialInstructions: specialInstructionsText
      }}
    );
  }

  const openFloatCart = () => {
    setIsOpen(true);
  };

  const closeFloatCart = () => {
    setIsOpen(false);
    setProductAddedState(true);
  };

  const addProduct = (product) => {
    const { cartProducts, updateCart } = props;
    let productAlreadyInCart = false;

    cartProducts.forEach(cp => {
      if (cp.id === product.id && cp.selectedSize === product.selectedSize) {
        cp.quantity += product.quantity;
        productAlreadyInCart = true;
      }
    });

    if (!productAlreadyInCart) {
      cartProducts.push(product);
    }

    updateCart(cartProducts);
    setProductAddedState(true);
    openFloatCart();
  };

  const removeProduct = (product) => {
    const { cartProducts, updateCart } = props;

    const index = cartProducts.findIndex(p => p.id === product.id && p.selectedSize === product.selectedSize);
    if (index >= 0) {
      cartProducts.splice(index, 1);
      updateCart(cartProducts);
    }
  };

  const changeProductQuantity = (changedProduct) => {
    const { cartProducts, updateCart } = props;

    const product = cartProducts.find(p => p.id === changedProduct.id && p.selectedSize === changedProduct.selectedSize);
    product.quantity = changedProduct.quantity;
    if (product.quantity <= 0) {
      removeProduct(product);
    }
    updateCart(cartProducts);
  }

  const handleSpecialInstructionsChange = (event) => {
    setSpecialInstructionsText(event.target.value);
  }

  const products = props.cartProducts.map(p => {
    return (
      <CartProduct product={p} removeProduct={props.removeProduct} changeProductQuantity={props.changeProductQuantity} key={p.id} currency={props.currency} />
    );
  });

  let classes = ['float-cart'];

  if (!!isOpen) {
    classes.push('float-cart--open');
  }

  let checkout;

  if (props.cartProducts.length > 0) {
    checkout = (<div style={{ padding: 2 }}>
      <Container>
        <Row>
          <Col></Col>
          <Col xs={6}><Button variant="primary" onClick={() => goToCheckoutPage()}>CHECK OUT</Button></Col>
          <Col></Col>
        </Row>
      </Container>
    </div>)
  } else {
    checkout = null;
  }

  let productFormatWithCurrency;

  productFormatWithCurrency = (
    <div className="sub-price">

      <p className="sub-price__val">
        $ <span> </span>{props.cartTotal.totalPrice}
      </p>
    </div>
  );

  return (
    <div className={classes.join(' ')}>
      {/* If cart open, show close (x) button */}
      {isOpen && (
        <div
          onClick={() => closeFloatCart()}
          className="float-cart__close-btn"
        >
          X
        </div>
      )}

      <div className="float-cart__content">
        <div className="float-cart__header">
          <span className="bag">
            <span className="bag__quantity">{props.cartTotal.productQuantity}</span>
          </span>
          <span className="header-title">Cart</span>
        </div>

        <div className="float-cart__shelf-container">
          {products}
          {!products.length && (
            <p className="shelf-empty">
              Add some products in the cart <br />
              :)
            </p>
          )}
          <p className="shelf-message">Special instructions for seller</p>
          <Form.Group style={{ marginLeft: "20px", marginRight: "20px" }}>

            <Form.Control
              value={specialInstructionsText}
              onChange={handleSpecialInstructionsChange}
              size="lg"
              type="text"
              placeholder="Wrap as a gift..."
              as="textarea"
              rows="3"
              maxLength={1000}
            />
          </Form.Group>

        </div>




        <div className="float-cart__footer">
          <div className="sub">SUBTOTAL</div>
          {productFormatWithCurrency}

          {checkout}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  cartProducts: state.cart.products,
  newProduct: state.cart.productToAdd,
  productToRemove: state.cart.productToRemove,
  productToChange: state.cart.productToChange,
  cartTotal: state.total.data,
  currency: state.currency.data
});

export default connect(
  mapStateToProps,
  { loadCart, updateCart, removeProduct, changeProductQuantity }
)(FloatCart);
