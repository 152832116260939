import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';

import "./style.css";
import Button from "react-bootstrap/Button";
const EmailConfirmation = (props) => {
  const navigate = useNavigate();
  const [serverMessage, setServerMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);


  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };

  const signMeIn = () => {
    navigate('/Login');
  };

  const isMenuOpen = (state) => {
    if (state.isOpen === false) {
      setMenuHidden(true);
    } else {
      setMenuHidden(false);
    }
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  useEffect(() => {
    const url = window.location.href;
    let localSelectorEmail = '';
    let localValidatorEmail = '';
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    const params = {};
    let match;

    while ((match = regex.exec(url))) {
      params[match[1]] = match[2];
      if (match[1] === 'selectorEmail') {
        localSelectorEmail = match[2];
      }
      if (match[1] === 'validatorEmail') {
        localValidatorEmail = match[2];
      }
    }

    if (localSelectorEmail && localValidatorEmail) {
      try {
        fetch(
          'https://stealthdocumentation.com/dourbeen_api_pdo.php?apicall=emailConfirmation',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: `selectorEmail=${localSelectorEmail}&validatorEmail=${localValidatorEmail}`
          }
        ).then(async function (data) {
          data.json().then(async function (data) {
            if (data.message === 'Email confirmed successfully') {
              setServerMessage('Thank you for confirming your email. You are all set.');
            } else {
              setServerMessage(data.message);
            }
          });
        });
      } catch (err) {
        setServerMessage(err);
      }
    } else {
      setServerMessage('Url is tampered');
    }
  }, []);

  const mainApplication = () => {
    navigate({
      pathname: "/"
    });
  }

  return (
    <div id="outer-container">
      <HamburgerMenu
        isMenuOpenFunc={isMenuOpen}
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        isOpenStatus={menuHidden}
        history={props.history}
        callbackClose={callbackRoutingCloseMenu}
      />
      <div id="page-wrap">
        <Header
          openMenu={openMenuWindow}
          openCart={openFloatCart}
          signIn={signMeIn}
          history={props.history}
        />
        <div className="App">
          <header className="App-header">
            <p style={{ textDecorationLine: 'underline' }}>Welcome to Ghumman Tech</p>
            <p>Email Confirmation</p>
            <p style={{ color: "red" }}>{serverMessage}</p>
            <Button onClick={() => mainApplication()}>
              Back to Web Application
            </Button>
          </header>
        </div>
        <FloatCart isTheCartOpen={isOpen} history={props.history} />
        <Footer history={props.history} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => (
  {
    authenticate: state
  })

const mapDispatchToProps = (dispatch) => ({
  login: (email, password) =>
    dispatch({ type: "UPDATE", email: email, password: password }),
  signout: () => dispatch({ type: 'LOGOUT' }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailConfirmation);