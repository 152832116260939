import { FETCH_PRODUCTS, FETCH_PRODUCT } from './actionTypes';

const initialState = {
  products: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return {
        ...state,
        products: action.payload
      };
      case FETCH_PRODUCT:
        return {
          ...state,
          products: action.payload
        };
    default:
      return state;
  }
}
